/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-undef */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */

import React, { useState, useEffect } from "react";
import { Table } from "antd";
import Breadcrumbs from "../../../../components/Breadcrumbs";

import { useTranslation } from "react-i18next";

import AuthUser from "../../../../components/AuthUser";
import Spinner from "../../../../components/Spinner";

const RegistoAtuacaoPosto = () => {
  const [atuacoes, setAtuacoes] = useState([]);

  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const { getToken, logout } = AuthUser();

  useEffect(() => {
    if (getToken() === undefined) {
      logout();
    } else {
      const fetchData = async () => {
        try {
          setLoading(true);
          await getAtuacoesSampleData();
          setLoading(false);
        } catch (error) {
          console.error("Erro ao obter dados:", error);
          setLoading(false);
        }
      };

      fetchData();
    }
  }, []);

  const getAtuacoesSampleData = async () => {
    const sampleData = [
      {
        id: 1,
        nome: "João Silva",
        passe: "12345",
        categoria: "Vigilante",
        posto: "Posto 1",
        hora: "08:30",
        data: "16/11/2024",
        infracao: "Ausência no local de trabalho sem aviso prévio.",
        medidas: "Advertência verbal e registro no sistema.",
      },
      {
        id: 2,
        nome: "Maria Oliveira",
        passe: "67890",
        categoria: "Supervisor",
        posto: "Posto 2",
        hora: "09:15",
        data: "16/11/2024",
        infracao: "Falta de uniforme adequado.",
        medidas: "Solicitação de correção imediata e notificação formal.",
      },
      {
        id: 3,
        nome: "Carlos Santos",
        passe: "11223",
        categoria: "Vigilante",
        posto: "Posto 3",
        hora: "10:00",
        data: "15/11/2024",
        infracao: "Uso de dispositivo pessoal durante o serviço.",
        medidas: "Recomendação de manter foco nas tarefas atribuídas.",
      },
      {
        id: 4,
        nome: "Fernanda Lima",
        passe: "44556",
        categoria: "Supervisor",
        posto: "Posto 4",
        hora: "11:30",
        data: "15/11/2024",
        infracao: "Saída não autorizada do posto.",
        medidas: "Advertência escrita e substituição temporária.",
      },
      {
        id: 5,
        nome: "Roberto Costa",
        passe: "77889",
        categoria: "Vigilante",
        posto: "Posto 5",
        hora: "07:45",
        data: "14/11/2024",
        infracao: "Atraso no início do turno.",
        medidas: "Desconto no pagamento de horas atrasadas.",
      },
    ];

    setAtuacoes(sampleData);
  };

  const columns = [
    {
      title: t("Nome"),
      dataIndex: "nome",
      render: (text) => <span>{text}</span>,
      sorter: (a, b) => a.nome.localeCompare(b.nome),
    },
    {
      title: t("Número do Passe"),
      dataIndex: "passe",
      render: (text) => <span>{text}</span>,
      sorter: (a, b) => a.passe.localeCompare(b.passe),
    },
    {
      title: t("Categoria"),
      dataIndex: "categoria",
      render: (text) => <span>{text}</span>,
      sorter: (a, b) => a.categoria.localeCompare(b.categoria),
    },
    {
      title: t("Posto"),
      dataIndex: "posto",
      render: (text) => <span>{text}</span>,
      sorter: (a, b) => a.posto.localeCompare(b.posto),
    },
    {
      title: t("Hora"),
      dataIndex: "hora",
      render: (text) => <span>{text}</span>,
      sorter: (a, b) => a.hora.localeCompare(b.hora),
    },
    {
      title: t("Data"),
      dataIndex: "data",
      render: (text) => <span>{text}</span>,
      sorter: (a, b) => a.data.localeCompare(b.data),
    },
    {
      title: t("Infração Cometida"),
      dataIndex: "infracao",
      render: (text) => <span>{text}</span>,
    },
    {
      title: t("Medidas Corretivas"),
      dataIndex: "medidas",
      render: (text) => <span>{text}</span>,
    },
  ];

  const totalAtuacoes = atuacoes.length;
  const totalInfracoes = atuacoes.filter((a) => a.infracao).length;
  const categoriasEnvolvidas = new Set(atuacoes.map((a) => a.categoria)).size;
  const totalPostos = new Set(atuacoes.map((a) => a.posto)).size;

  if (loading) return (<><Spinner></Spinner></>); else return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Breadcrumbs maintitle={t("Registo de Atuação no Posto")} title="Operações" />

          {/* Estatísticas */}
          <div className="row">
            <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
              <div className="card dash-widget">
                <div className="card-body">
                  <span className={`dash-widget-icon fa fa-cubes`} />
                  <div className="dash-widget-info">
                    <h3>{totalAtuacoes}</h3>
                    <span>Total de Registros</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
              <div className="card dash-widget">
                <div className="card-body">
                  <span className={`dash-widget-icon fa fa-exclamation-circle`} />
                  <div className="dash-widget-info">
                    <h3>{totalInfracoes}</h3>
                    <span>Total de Infrações</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
              <div className="card dash-widget">
                <div className="card-body">
                  <span className={`dash-widget-icon fa fa-users`} />
                  <div className="dash-widget-info">
                    <h3>{categoriasEnvolvidas}</h3>
                    <span>Categorias Envolvidas</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
              <div className="card dash-widget">
                <div className="card-body">
                  <span className={`dash-widget-icon fa fa-building`} />
                  <div className="dash-widget-info">
                    <h3>{totalPostos}</h3>
                    <span>Total de Postos</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                <Table
                  columns={columns}
                  dataSource={atuacoes}
                  rowKey="id"
                  pagination={{ pageSize: 5 }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegistoAtuacaoPosto;
