/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-undef */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */

import React, { useEffect, useState } from "react";
import axios from "axios";
import Breadcrumbs from "../../../../../components/Breadcrumbs";
import { useTranslation } from "react-i18next";
import Spinner from "../../../../../components/Spinner";
import {
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
} from "recharts";
import { format } from "date-fns";
import { toast, ToastContainer } from "react-toastify";
import AuthUser from "../../../../../components/AuthUser";
import StatisticsOperations from "./StatisticsOperations";
import ChartsOperations from "./ChartsOperations";
import Reports from "./Reports";

const OperationsDashboard = () => {
  const { t } = useTranslation();
  const { http, url_root } = AuthUser();

  const [loading, setLoading] = useState(false);
  //const [dashboardData, setDashboardData] = useState({});
  //const [monthlyData, setMonthlyData] = useState([]);

  const dashboardData = {
    escalas: [
      {
        id: 1,
        dataInicio: "2024-11-01",
        dataFim: "2024-11-30",
        faltas: 5,
        dobras: 3,
      },
      {
        id: 2,
        dataInicio: "2024-11-01",
        dataFim: "2024-11-15",
        faltas: 2,
        dobras: 4,
      },
      {
        id: 3,
        dataInicio: "2024-11-16",
        dataFim: "2024-11-30",
        faltas: 3,
        dobras: 2,
      },
    ],
    ocorrencias: [
      {
        id: 1,
        data: "2024-11-10",
        descricao: "Movimentação suspeita registrada.",
      },
      {
        id: 2,
        data: "2024-11-15",
        descricao: "Equipamento defeituoso reportado.",
      },
      { id: 3, data: "2024-11-20", descricao: "Visitante sem identificação." },
    ],
    supervisoes: [
      {
        id: 1,
        data: "2024-11-05",
        efetivoPresente: 3,
        situacao: "Operação normal.",
      },
      {
        id: 2,
        data: "2024-11-12",
        efetivoPresente: 4,
        situacao: "Gerador ativado.",
      },
      {
        id: 3,
        data: "2024-11-25",
        efetivoPresente: 2,
        situacao: "Segurança reforçada.",
      },
    ],
    atuacoes: [
      {
        id: 1,
        data: "2024-11-01",
        infracao: "Ausência sem aviso.",
        medidas: "Advertência verbal.",
      },
      {
        id: 2,
        data: "2024-11-10",
        infracao: "Falta de uniforme.",
        medidas: "Notificação formal.",
      },
      {
        id: 3,
        data: "2024-11-20",
        infracao: "Uso de dispositivo pessoal.",
        medidas: "Recomendação de foco.",
      },
    ],
    chaves: [
      {
        id: 1,
        data: "2024-11-05",
        setor: "Administração",
        solicitante: "João Silva",
        devolvida: true,
      },
      {
        id: 2,
        data: "2024-11-10",
        setor: "Manutenção",
        solicitante: "Maria Oliveira",
        devolvida: true,
      },
      {
        id: 3,
        data: "2024-11-15",
        setor: "TI",
        solicitante: "Carlos Santos",
        devolvida: false,
      },
    ],
    materiais: [
      {
        id: 1,
        data: "2024-11-01",
        tipo: "Equipamentos",
        entrada: "08:00",
        saida: "10:00",
      },
      {
        id: 2,
        data: "2024-11-10",
        tipo: "Materiais de construção",
        entrada: "09:30",
        saida: "11:30",
      },
      {
        id: 3,
        data: "2024-11-20",
        tipo: "Alimentos",
        entrada: "07:45",
        saida: "09:45",
      },
    ],
    veiculos: [
      {
        id: 1,
        data: "2024-11-01",
        tipo: "Caminhão",
        entrada: "08:00",
        saida: "10:00",
      },
      {
        id: 2,
        data: "2024-11-12",
        tipo: "Van",
        entrada: "09:00",
        saida: "11:00",
      },
      {
        id: 3,
        data: "2024-11-20",
        tipo: "Carro pequeno",
        entrada: "07:30",
        saida: "09:30",
      },
    ],
    pesagens: [
      { id: 1, data: "2024-11-05", bruto: 20000, tara: 5000, liquido: 15000 },
      { id: 2, data: "2024-11-12", bruto: 25000, tara: 8000, liquido: 17000 },
      { id: 3, data: "2024-11-20", bruto: 30000, tara: 7000, liquido: 23000 },
    ],
  };

  const monthlyData = [
    { categoria: "Escalas", total: dashboardData.escalas.length },
    { categoria: "Ocorrências", total: dashboardData.ocorrencias.length },
    { categoria: "Supervisões", total: dashboardData.supervisoes.length },
    { categoria: "Atuações", total: dashboardData.atuacoes.length },
    { categoria: "Chaves", total: dashboardData.chaves.length },
    { categoria: "Materiais", total: dashboardData.materiais.length },
    { categoria: "Veículos", total: dashboardData.veiculos.length },
    { categoria: "Pesagens", total: dashboardData.pesagens.length },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await http.get("/dashboard/operations"); // Fictício endpoint para o backend
        setDashboardData(response.data);
        generateMonthlyData(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Erro ao carregar os dados do dashboard:", error);
        toast.error("Erro ao carregar os dados do dashboard.");
        setLoading(false);
      }
    };

    //fetchData();
  }, []);

  const generateMonthlyData = (data) => {
    // Processando dados para um período de um mês
    const categories = [
      "Escalas",
      "Ocorrências",
      "Supervisões",
      "Atuações",
      "Chaves",
      "Materiais",
      "Veículos",
      "Pesagens",
    ];
    const processedData = categories.map((category) => ({
      categoria: category,
      total:
        data[category.toLowerCase()]?.length ||
        Math.floor(Math.random() * 50) + 10,
    }));
    setMonthlyData(processedData);
  };

  if (loading) return <Spinner />;

  return (
    <div className="main-wrapper">
      <div className="page-wrapper">
        <div className="content container-fluid">
          {/* Header */}
          <Breadcrumbs
            maintitle={t("Painel Das Operações")}
            title="Dashboard"
          />
          <div className="row">
            {/* Card: Escalas */}
            <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
              <div className="card dash-widget">
                <div className="card-body">
                  <span className="dash-widget-icon fa fa-calendar" />
                  <div className="dash-widget-info">
                    <h3>{dashboardData.escalas?.length || 0}</h3>
                    <span>{t("Total Escalas de Efetividade")}</span>
                  </div>
                </div>
              </div>
            </div>
            {/* Card: Livro de Ocorrências */}
            <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
              <div className="card dash-widget">
                <div className="card-body">
                  <span className="dash-widget-icon fa fa-exclamation-circle" />
                  <div className="dash-widget-info">
                    <h3>{dashboardData.ocorrencias?.length || 0}</h3>
                    <span>{t("Total Ocorrências")}</span>
                  </div>
                </div>
              </div>
            </div>
            {/* Card: Supervisão */}
            <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
              <div className="card dash-widget">
                <div className="card-body">
                  <span className="dash-widget-icon fa fa-shield" />
                  <div className="dash-widget-info">
                    <h3>{dashboardData.supervisoes?.length || 0}</h3>
                    <span>{t("Supervisões Realizadas")}</span>
                  </div>
                </div>
              </div>
            </div>
            {/* Card: Registro de Atuação */}
            <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
              <div className="card dash-widget">
                <div className="card-body">
                  <span className="dash-widget-icon fa fa-user-check" />
                  <div className="dash-widget-info">
                    <h3>{dashboardData.atuacoes?.length || 0}</h3>
                    <span>{t("Atuações Registradas")}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            {/* Card: Entregas de Chaves */}
            <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
              <div className="card dash-widget">
                <div className="card-body">
                  <span className="dash-widget-icon fa fa-key" />
                  <div className="dash-widget-info">
                    <h3>{dashboardData.chaves?.length || 0}</h3>
                    <span>{t("Entregas de Chaves")}</span>
                  </div>
                </div>
              </div>
            </div>
            {/* Card: Materiais em Trânsito */}
            <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
              <div className="card dash-widget">
                <div className="card-body">
                  <span className="dash-widget-icon fa fa-box" />
                  <div className="dash-widget-info">
                    <h3>{dashboardData.materiais?.length || 0}</h3>
                    <span>{t("Materiais em Trânsito")}</span>
                  </div>
                </div>
              </div>
            </div>
            {/* Card: Veículos em Trânsito */}
            <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
              <div className="card dash-widget">
                <div className="card-body">
                  <span className="dash-widget-icon fa fa-car" />
                  <div className="dash-widget-info">
                    <h3>{dashboardData.veiculos?.length || 0}</h3>
                    <span>{t("Veículos em Trânsito")}</span>
                  </div>
                </div>
              </div>
            </div>
            {/* Card: Pesagens Diárias */}
            <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
              <div className="card dash-widget">
                <div className="card-body">
                  <span className="dash-widget-icon fa fa-weight" />
                  <div className="dash-widget-info">
                    <h3>{dashboardData.pesagens?.length || 0}</h3>
                    <span>{t("Pesagens Diárias")}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <Reports /> */}

          <div className="row">
            <div className="col-md-12 text-center">
              <div className="card">
                <div className="card-body">

                  <StatisticsOperations
                    escalas={dashboardData.escalas}
                    ocorrencias={dashboardData.ocorrencias}
                    supervisoes={dashboardData.supervisoes}
                    atuacoes={dashboardData.atuacoes}
                    chaves={dashboardData.chaves}
                    materiais={dashboardData.materiais}
                    veiculos={dashboardData.veiculos}
                    pesagens={dashboardData.pesagens}
                  />

                  <ChartsOperations
                    escalas={dashboardData.escalas}
                    ocorrencias={dashboardData.ocorrencias}
                    supervisoes={dashboardData.supervisoes}
                    atuacoes={dashboardData.atuacoes}
                    chaves={dashboardData.chaves}
                    materiais={dashboardData.materiais}
                    veiculos={dashboardData.veiculos}
                    pesagens={dashboardData.pesagens}
                  />

                </div>
              </div>
            </div>
          </div>

          {/* Monthly Insights Bar Chart */}
          <div className="row">
            <div className="col-md-12 text-center">
              <div className="card">
                <div className="card-body">
                  <h3 className="card-title">{t("Destaques Mensais")}</h3>
                  <ResponsiveContainer width="100%" height={300}>
                    <BarChart
                      data={monthlyData}
                      margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="categoria" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Bar dataKey="total" fill="#8884d8" />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />
    </div>
  );
};

export default OperationsDashboard;
