import { useTranslation } from "react-i18next";

import React, { useState, useEffect } from "react";

import { toast, ToastContainer } from 'react-toastify';

import AuthUser from "../AuthUser";

const AlterarNomeEmesModal = ({ onUpdateNameSubmissionSuccess, nomeEdit, mesEdit, tipo }) => {

  const { t } = useTranslation(); 

  const [nome, setName] = useState('');
  const [mes, setMes] = useState();
  const [loading, setLoading] = useState(false);

  const triggerCloseButtonClick = (buttonId) => {
    const closeButton = document.getElementById(buttonId);
    if (closeButton) {
      closeButton.dispatchEvent(new Event('click'));
    }
  };


  useEffect(() => {

    setName(nomeEdit);
    setMes(mesEdit);

  }, [nomeEdit]);


  //registar quadro

  const submitNome = () => {

    // Basic form validation
    if (nome.length < 2) {

      // Show a error toast
      toast.error('A descrição deve ter pelo menos dois caracteres.', {
        position: 'top-right',
        autoClose: 3000, // Close the toast after 3 seconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

      return;
    }


    triggerCloseButtonClick('closeButton10');

    onUpdateNameSubmissionSuccess(nome,mes);

  };


  return (
    <>
      {/* Add Lista Modal */}
      <div
        id="alterar_descricao_modal"
        className="modal custom-modal fade"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">{t('Alterar Descrição')}</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                id="closeButton10"
              >
                ×
              </button>
            </div>
            <div className="modal-body">
              <form>

                <div className="input-block mb-3">
                  <label className="col-form-label">{t('Descrição')}</label>
                  <input type="text" className="form-control" value={nome} onChange={e => setName(e.target.value)} />
                </div>

                {
                  tipo == 1 ? <>
                    <div className="input-block mb-3 form-focus focused">
                      <label className="focus-label">{t("Mês")}</label>

                      <select
                        onChange={(e) => setMes(e.target.value)}
                        value={mes}
                        className="form-control select"
                      >
                        <option value="1">Janeiro</option>
                        <option value="2">Fevereiro</option>
                        <option value="3">Março</option>
                        <option value="4">Abril</option>
                        <option value="5">Maio</option>
                        <option value="6">Junho</option>
                        <option value="7">Julho</option>
                        <option value="8">Agosto</option>
                        <option value="9">Setembro</option>
                        <option value="10">Outubro</option>
                        <option value="11">Novembro</option>
                        <option value="12">Dezembro</option>
                      </select>
                    </div>
                  </> : <></>
                }

                <div className="submit-section m-t-20 text-center">
                  <a
                    className="btn btn-primary submit-btn"
                    onClick={submitNome}>
                    {t('Save')}
                  </a>

                </div>
              </form>
            </div>
          </div>
        </div>

      </div>




      <ToastContainer />

    </>
  );
};

export default AlterarNomeEmesModal;
