/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-undef */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */

import React, { useState, useEffect } from "react";
import { Table } from "antd";
import Breadcrumbs from "../../../../components/Breadcrumbs";

import { useTranslation } from "react-i18next";

import AuthUser from "../../../../components/AuthUser";
import Spinner from "../../../../components/Spinner";

const TransitoDeMaterial = () => {
  const [transitos, setTransitos] = useState([]);

  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const { getToken, logout } = AuthUser();

  useEffect(() => {
    if (getToken() === undefined) {
      logout();
    } else {
      const fetchData = async () => {
        try {
          setLoading(true);
          await getTransitoSampleData();
          setLoading(false);
        } catch (error) {
          console.error("Erro ao obter dados:", error);
          setLoading(false);
        }
      };

      fetchData();
    }
  }, []);

  const getTransitoSampleData = async () => {
    const sampleData = [
      {
        id: 1,
        data: "16/11/2024",
        codigoOperacao: "OP123",
        fornecedor: "Fornecedor A",
        matricula: "ABC-1234",
        assinaturaMotorista: "João Silva",
        identMotorista: "123456789",
        horarioEntrada: "08:00",
        tipoMaterial: "Equipamentos",
        horarioSaida: "10:00",
        vistoSeguranca: "Aprovado",
      },
      {
        id: 2,
        data: "16/11/2024",
        codigoOperacao: "OP124",
        fornecedor: "Fornecedor B",
        matricula: "XYZ-5678",
        assinaturaMotorista: "Maria Oliveira",
        identMotorista: "987654321",
        horarioEntrada: "09:30",
        tipoMaterial: "Materiais de Construção",
        horarioSaida: "11:30",
        vistoSeguranca: "Pendente",
      },
      {
        id: 3,
        data: "16/11/2024",
        codigoOperacao: "OP125",
        fornecedor: "Fornecedor C",
        matricula: "DEF-9012",
        assinaturaMotorista: "Carlos Santos",
        identMotorista: "123789456",
        horarioEntrada: "07:45",
        tipoMaterial: "Alimentos",
        horarioSaida: "09:45",
        vistoSeguranca: "Aprovado",
      },
      {
        id: 4,
        data: "16/11/2024",
        codigoOperacao: "OP126",
        fornecedor: "Fornecedor D",
        matricula: "GHI-3456",
        assinaturaMotorista: "Fernanda Lima",
        identMotorista: "456123789",
        horarioEntrada: "10:15",
        tipoMaterial: "Produtos Químicos",
        horarioSaida: "12:15",
        vistoSeguranca: "Aprovado",
      },
      {
        id: 5,
        data: "16/11/2024",
        codigoOperacao: "OP127",
        fornecedor: "Fornecedor E",
        matricula: "JKL-7890",
        assinaturaMotorista: "Roberto Costa",
        identMotorista: "789456123",
        horarioEntrada: "08:30",
        tipoMaterial: "Móveis",
        horarioSaida: "10:30",
        vistoSeguranca: "Pendente",
      },
    ];

    setTransitos(sampleData);
  };

  const columns = [
    {
      title: t("Nº"),
      dataIndex: "id",
      render: (text) => <span>{text}</span>,
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: t("Data"),
      dataIndex: "data",
      render: (text) => <span>{text}</span>,
      sorter: (a, b) => a.data.localeCompare(b.data),
    },
    {
      title: t("Cód. Oper."),
      dataIndex: "codigoOperacao",
      render: (text) => <span>{text}</span>,
      sorter: (a, b) => a.codigoOperacao.localeCompare(b.codigoOperacao),
    },
    {
      title: t("Fornecedor / Empresa"),
      dataIndex: "fornecedor",
      render: (text) => <span>{text}</span>,
      sorter: (a, b) => a.fornecedor.localeCompare(b.fornecedor),
    },
    {
      title: t("Matrícula"),
      dataIndex: "matricula",
      render: (text) => <span>{text}</span>,
      sorter: (a, b) => a.matricula.localeCompare(b.matricula),
    },
    {
      title: t("Assinatura do Motorista"),
      dataIndex: "assinaturaMotorista",
      render: (text) => <span>{text}</span>,
    },
    {
      title: t("Ident. Pessoal"),
      dataIndex: "identMotorista",
      render: (text) => <span>{text}</span>,
    },
    {
      title: t("Horário de Entrada"),
      dataIndex: "horarioEntrada",
      render: (text) => <span>{text}</span>,
      sorter: (a, b) => a.horarioEntrada.localeCompare(b.horarioEntrada),
    },
    {
      title: t("Tipo de Material"),
      dataIndex: "tipoMaterial",
      render: (text) => <span>{text}</span>,
    },
    {
      title: t("Horário de Saída"),
      dataIndex: "horarioSaida",
      render: (text) => <span>{text}</span>,
      sorter: (a, b) => a.horarioSaida.localeCompare(b.horarioSaida),
    },
    {
      title: t("Visto do Segurança"),
      dataIndex: "vistoSeguranca",
      render: (text) => <span>{text}</span>,
    },
  ];

  const totalRegistros = transitos.length;
  const totalAprovados = transitos.filter((t) => t.vistoSeguranca === "Aprovado").length;
  const tiposMateriais = new Set(transitos.map((t) => t.tipoMaterial)).size;
  const totalFornecedores = new Set(transitos.map((t) => t.fornecedor)).size;

  if (loading) return (<><Spinner></Spinner></>); else return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Breadcrumbs maintitle={t("Trânsito de Material")} title="Operações" />

          {/* Estatísticas */}
          <div className="row">
            <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
              <div className="card dash-widget">
                <div className="card-body">
                  <span className={`dash-widget-icon fa fa-cubes`} />
                  <div className="dash-widget-info">
                    <h3>{totalRegistros}</h3>
                    <span>Total de Registros</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
              <div className="card dash-widget">
                <div className="card-body">
                  <span className={`dash-widget-icon fa fa-check-circle`} />
                  <div className="dash-widget-info">
                    <h3>{totalAprovados}</h3>
                    <span>Vistos Aprovados</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
              <div className="card dash-widget">
                <div className="card-body">
                  <span className={`dash-widget-icon fa fa-tags`} />
                  <div className="dash-widget-info">
                    <h3>{tiposMateriais}</h3>
                    <span>Tipos de Materiais</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
              <div className="card dash-widget">
                <div className="card-body">
                  <span className={`dash-widget-icon fa fa-building`} />
                  <div className="dash-widget-info">
                    <h3>{totalFornecedores}</h3>
                    <span>Total de Fornecedores</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                <Table
                  columns={columns}
                  dataSource={transitos}
                  rowKey="id"
                  pagination={{ pageSize: 5 }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TransitoDeMaterial;
