import React, { useState, useEffect } from "react";
import Breadcrumbs from "../../../components/Breadcrumbs";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import AuthUser from "../../../components/AuthUser";
import { toast, ToastContainer } from "react-toastify";
import Spinner from "../../../components/Spinner";

const Definicoes = () => {
  const {
    http,
    url_root,
    user,
    user_permissoes,
    verificarPermissao,
    getMissao,
  } = AuthUser();

  const [contacto, setContacto] = useState(null);
  const [rhDefinicao, setRHdefinicao] = useState(null);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const [nome, setNome] = useState("");
  const [nif, setNif] = useState("");
  const [email, setEmail] = useState("");
  const [telefone, setTelefone] = useState("");
  const [endereco, setEndereco] = useState("");
  const [salario_minimo, setSalarioMinimo] = useState("");
  const [inss, setINSS] = useState("");
  const [abono, setAbono] = useState("");
  const [filho_idade, setFilho] = useState("");
  const [ferias, setFerias] = useState("");
  const [feria_valor, setFeriaValor] = useState("");
  const [natal_valor, setNatalValor] = useState("");
  const [percentagem_dobra, setPercentagemDobra] = useState(0);
  const [periodo1, setPeriodo1] = useState("");
  const [periodo2, setPeriodo2] = useState("");
  const [mes, setMes] = useState(0);

  const getDifinicoes = async () => {
    try {
      setLoading(true);
      const res = await http.get("/definicoes/" + getMissao()?.id);
      setContacto(res?.data?.contacto);
      setRHdefinicao(res?.data?.rhDefinicao);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getDifinicoes();
      } catch (error) {
        console.error("Erro ao obter definições:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (contacto) {
      setNome(contacto.nome || "");
      setNif(contacto.nif || "");
      setEmail(contacto.email || "");
      setTelefone(contacto.telefone || "");
      setEndereco(contacto.endereco || "");

      setimagemPreview(url_root + "/storage/" + contacto?.logo);
    }

    if (rhDefinicao) {
      setSalarioMinimo(rhDefinicao.salario_minimo || "");
      setINSS(rhDefinicao.inss || "");
      setAbono(rhDefinicao.abono_percentagem || "");
      setFilho(rhDefinicao.filho_idade_limite || "");
      setFerias(rhDefinicao.feria_dias || "");
      setFeriaValor(rhDefinicao.feria_valor || "");
      setNatalValor(rhDefinicao.natal_valor || "");
      setPercentagemDobra(rhDefinicao.percentagem_dobra || 0);
      setPeriodo1(rhDefinicao.periodo_ini || "");
      setPeriodo2(rhDefinicao.periodo_fim || "");
      setMes(rhDefinicao.periodo_mes || 0);
    }
  }, [contacto, rhDefinicao]);

  const submitUpdateContacto = async () => {
    if (verificarPermissao("UTILIZADORES", "atualizar")) {
      try {
        if (nome.length < 2) {
          setLoading(false);
          toast.error("O nome deve ter pelo menos dois caracteres.", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          return;
        }
        await updateContacto();
      } catch (error) {
        handleError(error);
      }
    } else {
      showNoPermissionAlert();
    }
  };

  const updateContacto = async () => {
    try {
      setLoading(true);

      const formData = new FormData();
      formData.append("id", contacto?.id);
      formData.append("nome", nome);
      formData.append("email", email);
      formData.append("telefone", telefone);
      formData.append("endereco", endereco);
      formData.append("nif", nif);
      formData.append("logo", imagem);

      const response = await http.post("/definicao/contacto/update", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      setLoading(false);
      toast.success(response.data.message, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } catch (error) {
      handleError(error);
    }
  };

  const submitUpdateRH = async () => {
    if (verificarPermissao("UTILIZADORES", "atualizar")) {
      try {
        await updateRH();
      } catch (error) {
        handleError(error);
      }
    } else {
      showNoPermissionAlert();
    }
  };

  const updateRH = async () => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("id", rhDefinicao?.id);
      formData.append("salario_minimo", salario_minimo);
      formData.append("inss", inss);
      formData.append("abono_percentagem", abono);
      formData.append("filho_idade_limite", filho_idade);
      formData.append("periodo_ini", periodo1);
      formData.append("periodo_fim", periodo2);
      formData.append("periodo_mes", mes);
      formData.append("feria_dias", ferias);
      formData.append("feria_valor", feria_valor);
      formData.append("natal_valor", natal_valor);
      formData.append("percentagem_dobra", percentagem_dobra);
      const response = await http.post(
        "/definicao/contacto/rhDefinicao",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      setLoading(false);
      toast.success(response.data.message, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } catch (error) {
      handleError(error);
    }
  };

  const handleError = (error) => {
    console.error(error);
    if (error.response && error.response.status === 401) {
      toast.error("Utilizador não autenticado.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } else {
      toast.error(
        "Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado",
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );
    }
  };

  const showNoPermissionAlert = () => {
    Swal.fire({
      title: "SEM PERMISSÃO",
      text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
      type: "error",
      showCancelButton: !0,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Certo, entendi!",
      confirmButtonClass: "btn btn-primary",
      cancelButtonClass: "btn btn-danger ml-1",
      buttonsStyling: !1,
    });
  };

  /* Imagem do logotipo */

  const [imagem, setSelectedFile] = useState(null);
  const [imagemPreview, setimagemPreview] = useState();

  const handleFileChange = (e) => {
    const newFile = e.target.files[0];

    // Lógica para processar a imagem, se necessário
    setSelectedFile(newFile);

    // Atualize diretamente o src da imagem
    const newFileURL = URL.createObjectURL(newFile);

    setimagemPreview(newFileURL);

    console.log("New image url 1:");
    console.log(newFileURL);
  };

  if (verificarPermissao("CARGO", "ver"))
    if (loading)
      return (
        <>
          <Spinner></Spinner>
        </>
      );
    else
      return (
        <>
          (
          <>
            <div className="page-wrapper">
              <div className="content container-fluid">
                <Breadcrumbs
                  maintitle={t("Definições importantes")}
                  title="Definição"
                  subtitle={t(
                    "Edite a informações como logotipo, contactos e definições de processos de recursos humanos"
                  )}
                />

                <div className="row">
                  <div className="col-md-6 d-flex">
                    <div className="card profile-box flex-fill">
                      <div className="card-body">
                        <h3 className="card-title">Contactos e Logotipo</h3>
                        <hr></hr>

                        <form>
                          <div className="row">
                            <div className="col-sm-12">
                              <div className="input-block mb-3 mb-3 form-focus focused">
                                <div>
                                  <input
                                    className="form-control"
                                    type="text"
                                    value={nome}
                                    onChange={(e) => setNome(e.target.value)}
                                  />
                                  <label className="focus-label">
                                    {t("NOME DA ORGANIZAÇÃO")}
                                  </label>
                                </div>
                              </div>
                            </div>

                            <div className="col-sm-12">
                              <div className="input-block mb-3 mb-3 form-focus focused">
                                <input
                                  className="form-control"
                                  type="text"
                                  value={nif}
                                  onChange={(e) => setNif(e.target.value)}
                                />
                                <label className="focus-label">
                                  {t("NIF")}{" "}
                                  <span className="text-danger">*</span>
                                </label>
                              </div>
                            </div>

                            <div className="col-sm-6 row">
                              <div className="col-sm-12">
                                <div className="input-block mb-3 mb-3 form-focus focused">
                                  <input
                                    className="form-control"
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                  />
                                  <label className="focus-label">
                                    {t("E-MAIL")}{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                </div>
                              </div>

                              <div className="col-sm-12">
                                <div className="input-block mb-3 mb-3 form-focus focused">
                                  <input
                                    className="form-control"
                                    type="text"
                                    value={telefone}
                                    onChange={(e) =>
                                      setTelefone(e.target.value)
                                    }
                                  />
                                  <label className="focus-label">
                                    {t("TELEFONE")}{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                </div>
                              </div>
                            </div>

                            <div className="col-sm-6">
                              <div className="profile-img-wrap edit-img">
                                <img
                                  className="inline-block"
                                  src={imagemPreview}
                                  alt={contacto?.logo}
                                />
                                <div className="fileupload btn">
                                  <span className="btn-text">editar</span>
                                  <input
                                    className="upload"
                                    type="file"
                                    onChange={handleFileChange}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="col-sm-12">
                              <div className="input-block mb-3 mb-3 form-focus focused">
                                <textarea
                                  className="form-control"
                                  rows={7}
                                  value={endereco}
                                  onChange={(e) => setEndereco(e.target.value)}
                                ></textarea>
                                <label className="focus-label">
                                  {t("ENDEREÇO")}{" "}
                                  <span className="text-danger">*</span>
                                </label>
                              </div>
                            </div>

                            <div className="col-sm-12 mb-4">
                              <div className="submit-section m-t-20 text-center">
                                {loading ? (
                                  "A processar"
                                ) : (
                                  <a
                                    className="btn btn-primary submit-btn"
                                    onClick={submitUpdateContacto}
                                  >
                                    {t("Salvar")}
                                  </a>
                                )}
                              </div>
                            </div>

                            <hr className=""></hr>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 d-flex">
                    <div className="card profile-box flex-fill">
                      <div className="card-body">
                        <h3 className="card-title">{t("Definições de RH")}</h3>

                        <hr></hr>

                        <div className="row">
                          <div className="col-sm-6">
                            <div className="input-block mb-3 mb-3 form-focus focused">
                              <input
                                className="form-control"
                                type="number"
                                value={salario_minimo}
                                onChange={(e) =>
                                  setSalarioMinimo(e.target.value)
                                }
                              />
                              <label className="focus-label">
                                {t("Salário mínimo nacional")}{" "}
                                <span className="text-danger">*</span>
                              </label>
                            </div>
                          </div>

                          <div className="col-sm-6">
                            <div className="input-block mb-3 mb-3 form-focus focused">
                              <input
                                className="form-control"
                                type="number"
                                value={inss}
                                onChange={(e) => setINSS(e.target.value)}
                              />
                              <label className="focus-label">
                                {t("% Do INSS")}{" "}
                                <span className="text-danger">*</span>
                              </label>
                            </div>
                          </div>

                          <div className="col-sm-6">
                            <div className="input-block mb-3 mb-3 form-focus focused">
                              <input
                                className="form-control"
                                type="number"
                                title="Percentagem correspondente ao montante total do abono permitido no salario do colaborador"
                                value={abono}
                                onChange={(e) => setAbono(e.target.value)}
                              />
                              <label className="focus-label">
                                {t("Abono de familia (limite)")}{" "}
                                <span className="text-danger">*</span>
                              </label>
                            </div>
                          </div>

                          <div className="col-sm-6">
                            <div className="input-block mb-3 mb-3 form-focus focused">
                              <input
                                className="form-control"
                                type="number"
                                value={filho_idade}
                                onChange={(e) => setFilho(e.target.value)}
                              />
                              <label className="focus-label">
                                {t("Idade Limite Dos Filhos Para o Abono")}{" "}
                                <span className="text-danger">*</span>
                              </label>
                            </div>
                          </div>

                          <div className="col-sm-4">
                            <div className="input-block mb-3 mb-3 form-focus focused">
                              <input
                                className="form-control"
                                type="number"
                                value={ferias}
                                onChange={(e) => setFerias(e.target.value)}
                              />
                              <label className="focus-label">
                                {t("Limite Dias De Ferias Por Ano")}{" "}
                                <span className="text-danger">*</span>
                              </label>
                            </div>
                          </div>

                          <div className="col-sm-4">
                            <div className="input-block mb-3 mb-3 form-focus focused">
                              <input
                                className="form-control"
                                type="number"
                                value={feria_valor}
                                onChange={(e) => setFeriaValor(e.target.value)}
                              />
                              <label className="focus-label">
                                {t("% Subsidio De Férias")}{" "}
                                <span className="text-danger">*</span>
                              </label>
                            </div>
                          </div>

                          <div className="col-sm-4">
                            <div className="input-block mb-3 mb-3 form-focus focused">
                              <input
                                className="form-control"
                                type="number"
                                value={natal_valor}
                                onChange={(e) => setNatalValor(e.target.value)}
                              />
                              <label className="focus-label">
                                {t("% Subsidio De Natal")}{" "}
                                <span className="text-danger">*</span>
                              </label>
                            </div>
                          </div>

                          <div
                            className="col-sm-4"
                            title="Periodo Inicial Para Processamento Salárial"
                          >
                            <div className="input-block mb-3 mb-3 form-focus focused">
                              <input
                                className="form-control"
                                type="number"
                                value={periodo1}
                                onChange={(e) => setPeriodo1(e.target.value)}
                              />
                              <label className="focus-label">
                                {t("Periodo Inicial")}{" "}
                                <span className="text-danger">*</span>
                              </label>
                            </div>
                          </div>

                          <div
                            className="col-sm-4"
                            title="Periodo Final Para Processamento Salárial"
                          >
                            <div className="input-block mb-3 mb-3 form-focus focused">
                              <input
                                className="form-control"
                                type="number"
                                value={periodo2}
                                onChange={(e) => setPeriodo2(e.target.value)}
                              />
                              <label className="focus-label">
                                {t("Periodo Final")}{" "}
                                <span className="text-danger">*</span>
                              </label>
                            </div>
                          </div>

                          <div className="col-sm-4" title="Definição do mês">
                            <div className="input-block mb-3 mb-3 form-focus focused">
                              <select
                                className="form-control"
                                onChange={(e) => setMes(e.target.value)}
                                value={mes}
                              >
                                <option value="0"> - No mesmo mês</option>
                                <option value="-1"> - Começa um mês antes </option>
                                <option value="1"> - Termina no mês a seguir</option>
                              </select>
                              <label className="focus-label">
                                {t("Defina o mês do intervalo")}{" "}
                              </label>
                            </div>
                          </div>

                          <div
                            className="col-sm-4"
                            title="Percentagem do valor do sálario dia a remunerar um dobrante"
                          >
                            <div className="input-block mb-3 mb-3 form-focus focused">
                              <input
                                className="form-control"
                                type="number"
                                value={percentagem_dobra}
                                onChange={(e) =>
                                  setPercentagemDobra(e.target.value)
                                }
                              />
                              <label className="focus-label">
                                {t("Percentagem da dobra")}{" "}
                                <span className="text-danger">*</span>
                              </label>
                            </div>
                          </div>

                          <div className="col-sm-12 mb-4">
                            <div className="submit-section m-t-20 text-center">
                              {loading ? (
                                "A processar"
                              ) : (
                                <a
                                  className="btn btn-primary submit-btn"
                                  onClick={submitUpdateRH}
                                >
                                  {t("Salvar")}
                                </a>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>{" "}
          </>
          ); <ToastContainer />
        </>
      );
  else
    return (
      <>
        <div className="page-wrapper cardhead">
          <div className="content ">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <h3 className="page-title">SEM PERMISSÃO</h3>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title">
                      <i className="fa fa-times-circle text-danger"></i>&nbsp;
                      OPERAÇÃO NÃO AUTORIZADA
                    </h4>
                  </div>
                  <div className="card-body">
                    <p>
                      Esta operação não é autorizada para o seu nivel de acesso
                      ao sistema, por favor contacte o administrador do sistema.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
};

export default Definicoes;
