/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-undef */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import StatisticsRH from "./StatisticsRH";
import ChartsRH from "./ChartsRH";
import Breadcrumbs from "../../../../../components/Breadcrumbs";
import { useTranslation } from "react-i18next";
import DashboardRHStats from "./DashboardRHStats";
import DashboardRHValues from "./DashboardRHValues";
import AlterarMesEAnoModal from "../../../../../components/modelpopup/AlterarMesEAnoModal";

import Swal from "sweetalert2";
import Spinner from "../../../../../components/Spinner";
import { toast, ToastContainer } from 'react-toastify';

import AuthUser from "../../../../../components/AuthUser";
import { startOfMonth, endOfMonth, format } from "date-fns";

const DashboardRH = () => {

  // eslint-disable-next-line no-unused-vars
  const { http, url_root, user, user_permissoes, verificarPermissao, getMissao, getToken } =
    AuthUser();

  const [loading, setLoading] = useState(false);

  const currentDate = new Date();

  const [mes, setMes] = useState(format(currentDate, "MM"));
  const [ano, setAno] = useState(format(currentDate, "yyyy"));

  const [periodo, setPeriodo] = useState('');
  const { t } = useTranslation();

  const [dashboardData, setDashboardData] = useState({
    totalSalarios: 0,
    totalSalariosPagos: 0,
    totalSalariosNaoPagos: 0,
    totalINSS: 0,
    totalIRT: 0,
    dividas: 0,
    colaboradores_atual: 0,
    colaboradores_anterior: 0,
  });

  const [salariosPorCargo, setSalariosPorCargo] = useState([]);


  const absentismoMes = [
    { dia: "01/11", total: 3 },
    { dia: "05/11", total: 2 },
    { dia: "10/11", total: 5 },
    { dia: "15/11", total: 1 },
    { dia: "20/11", total: 4 },
    { dia: "25/11", total: 6 },
    { dia: "30/11", total: 2 },
  ];

  useEffect(() => {

    if (getToken() == undefined) {
      logout();
      navigate("/");
    } else {

      setPeriodo(meses[mes-1] + ' - ' + ano);

      const fetchData = async () => {
        try {
          // Chama a função assíncrona getUtilizador()
          await consultar();
        } catch (error) {
          console.error('Erro ao obter utilizadores:', error);
        }
      };

      // Chama a função fetchData() para iniciar o processo
      fetchData();

    }

  }, [mes, ano]);


  const consultar = async () => {
    try {

      setLoading(true);

      const formData = new FormData();

      formData.append("mes", mes);
      formData.append("ano", ano);
      formData.append("missao_id", getMissao()?.id);

      const response = await http.post(
        "/folha_salarial/mes/ano/dashboard",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      setLoading(false);

      console.log(response?.data);
      periodo_alterado(response?.data);


    } catch (error) {

      toast.error(
        "Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.",
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
        }
      );
      
    }
  };

  const processarDados = (dados) => {

    let totalSalarios = 0;
    let totalSalariosPagos = 0;
    let totalSalariosNaoPagos = 0;
    let totalINSS = 0;
    let totalIRT = 0;
    let dividas = 0;
    let colaboradores_atual = dados?.colaboradores_ate_mes_selecionado;
    let colaboradores_anterior = dados?.colaboradores_ate_mes_anterior;
    const cargosMap = {};

    console.log('mes_selecionado');
    console.log(dados?.mes_selecionado);

    // Processar folhas salariais do mês selecionado
    dados?.mes_selecionado?.forEach(folha => {
      folha.salarios.forEach(salario => {
        totalSalarios += salario.salario_iliquido;
        totalINSS += salario.INSS;
        totalIRT += salario.IRT;
        dividas += salario.divida;

        if (salario.pago == 1) {
          totalSalariosPagos += salario.salario_iliquido;
        } else {
          totalSalariosNaoPagos += salario.salario_iliquido;
        }

        // Contabilizar salários por cargo
        if (!cargosMap[salario.cargo]) {
          cargosMap[salario.cargo] = 0;
        }
        cargosMap[salario.cargo] += salario.salario_iliquido;
      });
    });

    // Converter cargosMap para array
    const salariosPorCargo = Object.keys(cargosMap).map(cargo => ({
      cargo,
      total: cargosMap[cargo],
    }));

    // Atualizar estados
    setDashboardData({
      totalSalarios,
      totalSalariosPagos,
      totalSalariosNaoPagos,
      totalINSS,
      totalIRT,
      dividas,
      colaboradores_atual,
      colaboradores_anterior,
    });

    setSalariosPorCargo(salariosPorCargo);

  };

  const periodo_alterado = async (resultado) => {

    console.log('Resultado da consulta:');
    console.log(resultado);

    setPeriodo(meses[resultado?.mes - 1] + ' - ' + resultado?.ano);

    processarDados(resultado);

  };


  const mes_ano_modal = (resultado) =>{

    setMes(resultado?.mes);
    setAno(resultado?.ano);

  }

  const meses = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];

  const formatNumber = (number) => {
    return new Intl.NumberFormat("pt-PT", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(number);
  };


   return (
    <div className="main-wrapper">
      <div className="page-wrapper">
        <div className="content container-fluid">
          {/* Header do dashboard */}
          <Breadcrumbs
            maintitle={t("Painel RH")}
            title={"Dashboard / " + periodo}
            modal="#alterar_mes_ano_modal"
            name={t('Alterar Mes E Ano')}
            Linkname="/RH"
            Linkname1="/RH-DASHBOARD"
          />

          <DashboardRHValues
            dashboardData={dashboardData}
          />

          <DashboardRHStats dashboardData={dashboardData} />

          {/* Estatísticas Gerais */}
          {/* <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <h3 className="card-title">{t("Estatísticas Gerais")}</h3>
                  <StatisticsRH {...dashboardData} />
                </div>
              </div>
            </div>
          </div> */}

          {/* Gráficos */}
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <h3 className="card-title">{t("Gráficos de Desempenho")}</h3>
                  <ChartsRH
                    absentismoMes={absentismoMes}
                    salariosPorCargo={salariosPorCargo}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      {loading?<Spinner></Spinner>:<></>}

      <AlterarMesEAnoModal onSubmitSuccess={mes_ano_modal} />

      <ToastContainer />

    </div>
  );
};

export default DashboardRH;
