import React from "react";
import { useTranslation } from "react-i18next";

const StatisticsOperations = ({
  escalas,
  ocorrencias,
  supervisoes,
  atuacoes,
  chaves,
  materiais,
  veiculos,
  pesagens,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="row">
        <div className="col-md-12 col-lg-6 col-xl-3 d-flex">
          <div className="card flex-fill dash-statistics">
            <div className="card-body">
              <h5 className="card-title">{t("Efetividade Escalas")}</h5>
              <div className="stats-list">
                <div className="stats-info">
                  <p>
                    {t("Faltas")}
                    <strong>
                      {escalas.reduce((total, esc) => total + esc.faltas, 0)}
                    </strong>
                  </p>
                  <div className="progress">
                    <div
                      className="progress-bar bg-danger"
                      role="progressbar"
                      data-bs-toggle="tooltip"
                      title={Math.round(15) + "%"}
                      style={{
                        width: `${22}%`,
                      }}
                      aria-valuenow={62}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    />
                  </div>
                </div>
                <div className="stats-info">
                  <p>
                    {t("Dobras")}
                    <strong>
                      {escalas.reduce((total, esc) => total + esc.dobras, 0)}
                    </strong>
                  </p>
                  <div className="progress">
                    <div
                      className="progress-bar bg-primary"
                      role="progressbar"
                      data-bs-toggle="tooltip"
                      title={Math.round(15) + "%"}
                      style={{
                        width: `${56}%`,
                      }}
                      aria-valuenow={62}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-12 col-lg-6 col-xl-3 d-flex">
          <div className="card flex-fill dash-statistics">
            <div className="card-body">
              <h5 className="card-title">{t("Livro de Ocorrências")}</h5>
              <div className="stats-list">
                <div className="stats-info">
                  <p>
                    {t("Total Ocorrências")}
                    <strong>{ocorrencias.length}</strong>
                  </p>
                  <div className="progress">
                    <div
                      className="progress-bar bg-danger"
                      role="progressbar"
                      data-bs-toggle="tooltip"
                      title={Math.round(15) + "%"}
                      style={{
                        width: `${45}%`,
                      }}
                      aria-valuenow={62}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-12 col-lg-6 col-xl-3 d-flex">
          <div className="card flex-fill dash-statistics">
            <div className="card-body">
              <h5 className="card-title">{t("Supervisões 24h")}</h5>
              <div className="stats-list">
                <div className="stats-info">
                  <p>
                    {t("Supervisões Realizadas")}
                    <strong>{supervisoes.length}</strong>
                  </p>
                  <div className="progress">
                    <div
                      className="progress-bar bg-success"
                      role="progressbar"
                      data-bs-toggle="tooltip"
                      title={Math.round(15) + "%"}
                      style={{
                        width: `${45}%`,
                      }}
                      aria-valuenow={62}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-12 col-lg-6 col-xl-3 d-flex">
          <div className="card flex-fill dash-statistics">
            <div className="card-body">
              <h5 className="card-title">{t("Pesagens Diárias")}</h5>
              <div className="stats-list">
                <div className="stats-info">
                  <p>
                    {t("Total Pesagens")}
                    <strong>{pesagens.length}</strong>
                  </p>
                  <div className="progress">
                    <div
                      className="progress-bar bg-warning"
                      role="progressbar"
                      data-bs-toggle="tooltip"
                      title={Math.round(15) + "%"}
                      style={{
                        width: `${45}%`,
                      }}
                      aria-valuenow={62}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatisticsOperations;
