import React, { useState, useEffect } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";
import { useTranslation } from "react-i18next";

const ChartsOperations = ({
  escalas,
  ocorrencias,
  supervisoes,
  atuacoes,
  chaves,
  materiais,
  veiculos,
  pesagens,
}) => {
  const { t } = useTranslation();
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    const combinedData = [
      { categoria: t("Escalas"), total: escalas.length },
      { categoria: t("Ocorrências"), total: ocorrencias.length },
      { categoria: t("Supervisões"), total: supervisoes.length },
      { categoria: t("Atuações"), total: atuacoes.length },
      { categoria: t("Chaves"), total: chaves.length },
      { categoria: t("Materiais"), total: materiais.length },
      { categoria: t("Veículos"), total: veiculos.length },
      { categoria: t("Pesagens"), total: pesagens.length },
    ];
    setChartData(combinedData);
  }, [escalas, ocorrencias, supervisoes, atuacoes, chaves, materiais, veiculos, pesagens, t]);

  return (
    <div>
      <div className="row">
        <div className="col-md-12 text-center">
          <div className="card">
            <div className="card-body">
              <h3 className="card-title">{t("Estatísticas de Operações")}</h3>
              <ResponsiveContainer width="100%" height={300}>
                <LineChart
                  data={chartData}
                  margin={{ top: 5, right: 5, left: 5, bottom: 5 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="categoria" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line
                    type="monotone"
                    dataKey="total"
                    stroke="#8884d8"
                    activeDot={{ r: 8 }}
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChartsOperations;
