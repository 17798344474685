import axios from 'axios';
import { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';

export default function AuthUser() {

    // const navigate = useNavigate();
    const [token, setToken] = useState(getToken());
    const [user, setUser] = useState(getUser());
    const [missao, setMissao] = useState(getMissao());
    const [contacto, setContacto] = useState(getContacto());
    const [missoes, setMissoes] = useState(getMissoes());
    const [empresa_selecionada, setEmpresa] = useState(getEmpresa());
    const [logoTipo, setLogoTipo] = useState();
    const [user_permissoes, setPermissoes] = useState([]);
    const [multipla_visibilidade, setMultiplaVisibilidade] = useState(getMultiplaVisibilidade());

    const url_root = "https://sisgema-alvjamba-api.alv-jamba.com";
    //const url_root = "https://api-projeto-artefe.equalizador.ao";
    //const url_root = "https://api-projeto-equalizador.equalizador.ao";
    //const url_root = "https://projetos-artefe.excelproexpert.ao";
    //const url_root = "http://192.168.1.170:8000";
    //const url_root = "http://127.0.0.1:8000";
    // const url_root = "https://admin.excelproexpert.ao";
    // const url_root = "https://trainningbusiness.kahewa.ao";

    useEffect(() => {
        setLogoTipo('/static/media/logo-white.6d4161d9bceda0741c4e.png');
        setUser(getUser());
        setMultiplaVisibilidade(getMultiplaVisibilidade());
        setEmpresa(getEmpresa());
        setPermissoes(getPermissoes());
        setMissao(getMissao());
        setContacto(getContacto());
        setMissoes(getMissoes());
    }, [token]);

    function getToken() {
        const tokenString = sessionStorage.getItem('token');
        if (!tokenString) return null;
        try {
            return JSON.parse(tokenString);
        } catch (e) {
            console.error("Error parsing token from sessionStorage", e);
            return null;
        }
    }

    function getUser() {
        const userString = sessionStorage.getItem('user');
        if (!userString) return null;
        try {
            return JSON.parse(userString);
        } catch (e) {
            console.error("Error parsing user from sessionStorage", e);
            return null;
        }
    }

    function getMissao() {
        const missaoString = sessionStorage.getItem('missao');
        if (!missaoString) return null;
        try {
            return JSON.parse(missaoString);
        } catch (e) {
            console.error("Error parsing missao from sessionStorage", e);
            return null;
        }
    }

    function getContacto() {
        const contactoString = sessionStorage.getItem('contacto');
        if (!contactoString) return null;
        try {
            return JSON.parse(contactoString);
        } catch (e) {
            console.error("Error parsing contacto from sessionStorage", e);
            return null;
        }
    }

    function getMissoes() {
        const missoesString = sessionStorage.getItem('missoes');
        if (!missoesString) return null;
        try {
            return JSON.parse(missoesString);
        } catch (e) {
            console.error("Error parsing missoes from sessionStorage", e);
            return null;
        }
    }

    function getEmpresa() {
        const empresaString = sessionStorage.getItem('empresa');
        if (!empresaString) return null;
        try {
            return JSON.parse(empresaString);
        } catch (e) {
            console.error("Error parsing empresa from sessionStorage", e);
            return null;
        }
    }

    function getMultiplaVisibilidade() {
        const visibilidadeString = sessionStorage.getItem('multipla_visibilidade');
        if (!visibilidadeString) return null;
        try {
            return JSON.parse(visibilidadeString);
        } catch (e) {
            console.error("Error parsing multipla_visibilidade from sessionStorage", e);
            return null;
        }
    }

    function getPermissoes() {
        const permissoesString = sessionStorage.getItem('permissoes');
        if (!permissoesString) return null;
        try {
            return JSON.parse(permissoesString);
        } catch (e) {
            console.error("Error parsing permissoes from sessionStorage", e);
            return null;
        }
    }

    const saveToken = (user, permissoes, token, empresa, multipla_visibilidade, missao, missoes, contacto) => {
        sessionStorage.setItem('token', JSON.stringify(token));
        sessionStorage.setItem('user', JSON.stringify(user));
        sessionStorage.setItem('empresa', JSON.stringify(empresa));
        sessionStorage.setItem('permissoes', JSON.stringify(permissoes));
        sessionStorage.setItem('multipla_visibilidade', JSON.stringify(multipla_visibilidade));
        sessionStorage.setItem('missao', JSON.stringify(missao));
        sessionStorage.setItem('contacto', JSON.stringify(contacto));
        sessionStorage.setItem('missoes', JSON.stringify(missoes));

        setToken(token);
        setPermissoes(permissoes);
        setEmpresa(empresa);
        setMultiplaVisibilidade(multipla_visibilidade);
        setMissao(missao);
        setContacto(contacto);
        setMissoes(missoes);
        // navigate('/dashboard');
    }

    const saveMissao = (missao) => {
        sessionStorage.setItem('missao', JSON.stringify(missao));
        setMissao(missao);
    }

    const saveContacto = (contacto) => {
        sessionStorage.setItem('contacto', JSON.stringify(contacto));
        setContacto(contacto);
    }

    const logout = () => {
        sessionStorage.clear();
        // navigate('/entrar');
    }

    const http = axios.create({
        baseURL: url_root + "/api",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
        }
    });

    const verificarPermissao = (codigo, operacao) => {
        for (let i = 0; i < user_permissoes.length; i++) {
            const permissao = user_permissoes[i];
            let op = false;
            if (operacao.toLowerCase() == 'ver') {
                op = permissao.ver == 1;
            } else if (operacao.toLowerCase() == 'atualizar') {
                op = permissao.atualizar == 1;
            } else if (operacao.toLowerCase() == 'adicionar') {
                op = permissao.adicionar == 1;
            } else if (operacao.toLowerCase() == 'remover') {
                op = permissao.remover == 1;
            } else if (operacao.toLowerCase() == 'ativar') {
                op = permissao.ativar == 1;
            } else {
                op = false;
            }

            if (permissao.accao.codigo.toLowerCase() === codigo.toLowerCase() && op) {
                return true; // Permissão concedida
            }
        }
        return false; // Permissão não concedida
    };

    return {
        setToken: saveToken,
        setMissaoSelecionada: saveMissao,
        token,
        user,
        getMissao,
        getContacto,
        getMissoes,
        user_permissoes,
        getToken,
        http,
        logout,
        url_root,
        logoTipo,
        verificarPermissao,
        empresa_selecionada,
        multipla_visibilidade,
    }
}
