import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const DashboardRHStats = ({ dashboardData }) => {
  const { t } = useTranslation();

  const formatNumber = (number) => {
    return new Intl.NumberFormat("pt-PT", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(number);
  };

  const calculatePercentage = (current, previous) => {
    if (!previous || previous == 0) return "N/A";
    return ((current - previous) / previous * 100).toFixed(2) + "%";
  };

  const calculateProgress = (current, previous) => {
    if (!previous || previous == 0) return 0;
    const progress = (current / previous) * 100;
    return Math.min(progress, 100); // Limitar o progresso a 100%
  };

  const getValueOrZero = (value) => (value ? value : 0);

  const totalColaboradores = getValueOrZero(dashboardData?.colaboradores_atual?.length);
  const totalColaboradoresAnterior = getValueOrZero(dashboardData?.colaboradores_anterior?.length);
  const progressoColaboradores = calculateProgress(totalColaboradores, totalColaboradoresAnterior);
  const crescimentoColaboradores = calculatePercentage(totalColaboradores, totalColaboradoresAnterior);

  const salariosProcessados = getValueOrZero(dashboardData?.totalSalarios);
  const salariosPagos = getValueOrZero(dashboardData?.totalSalariosPagos);
  const salariosNaoPagos = getValueOrZero(dashboardData?.totalSalariosNaoPagos);
  const totalINSS = getValueOrZero(dashboardData?.totalINSS);
  const totalIRT = getValueOrZero(dashboardData?.totalIRT);
  const dividasDescontadas = getValueOrZero(dashboardData?.dividas);

  return (
    <div className="row">
      {/* Card: Total de Colaboradores */}
      <div className="col-md-6 col-lg-3 mb-3">
        <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-between mb-3">
              <div>
                <span className="d-block">{t("Total de colaboradores")}</span>
              </div>
              <div>
                <span className="text-success">{crescimentoColaboradores}</span>
              </div>
            </div>
            <h3 className="mb-3">{totalColaboradores}</h3>
            <div className="progress mb-2" style={{ height: "5px" }}>
              <div
                className="progress-bar bg-dark"
                role="progressbar"
                style={{ width: `${progressoColaboradores}%` }}
                aria-valuenow={progressoColaboradores}
                aria-valuemin={0}
                aria-valuemax={100}
              />
            </div>
            <p className="mb-0">
              {t("Comparado ao mês anterior")}
              <span className="text-muted"> {totalColaboradoresAnterior}</span>
            </p>
          </div>
        </div>
      </div>

      {/* Card: Salários Pagos */}
      <div className="col-md-6 col-lg-3 mb-3">
        <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-between mb-3">
              <div>
                <span className="d-block">{t("Salários Pagos")}</span>
              </div>
            </div>
            <h3 className="mb-3">{formatNumber(salariosPagos)}</h3>
            <div className="progress mb-2" style={{ height: "5px" }}>
              <div
                className="progress-bar bg-success"
                role="progressbar"
                style={{ width: `${calculateProgress(salariosPagos, 100)}%` }}
                aria-valuenow={calculateProgress(salariosPagos, 100)}
                aria-valuemin={0}
                aria-valuemax={100}
              />
            </div>
            <p className="mb-0">
              {t("Comparado ao mês anterior")}
              <span className="text-muted"> {t("x pagos")}</span>
            </p>
          </div>
        </div>
      </div>

      {/* Card: INSS do Mês */}
      <div className="col-md-6 col-lg-3 mb-3">
        <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-between mb-3">
              <div>
                <span className="d-block">{t("INSS do Mês")}</span>
              </div>
            </div>
            <h3 className="mb-3">{formatNumber(totalINSS)}</h3>
            <div className="progress mb-2" style={{ height: "5px" }}>
              <div
                className="progress-bar bg-secondary"
                role="progressbar"
                style={{ width: `${calculateProgress(totalINSS, 100)}%` }}
                aria-valuenow={calculateProgress(totalINSS, 100)}
                aria-valuemin={0}
                aria-valuemax={100}
              />
            </div>
            <p className="mb-0">
              {t("Comparado ao mês anterior")}
              <span className="text-muted"> {"14200"}</span>
            </p>
          </div>
        </div>
      </div>

        {/* Card: IRT do Mês */}
        <div className="col-md-6 col-lg-3 mb-3">
        <div className="card">

          <div className="card-body">
            <div className="d-flex justify-content-between mb-3">
              <div>
                <span className="d-block">{t("IRT do Mês")}</span>
              </div>
            </div>
            <h3 className="mb-3">{formatNumber(totalIRT)}</h3>
            <div className="progress mb-2" style={{ height: "5px" }}>
              <div
                className="progress-bar bg-secondary"
                role="progressbar"
                style={{ width: `${calculateProgress(totalIRT, 100)}%` }}
                aria-valuenow={calculateProgress(totalIRT, 100)}
                aria-valuemin={0}
                aria-valuemax={100}
              />
            </div>

            <p className="mb-0">

              {t("Comparado ao mês anterior")}
              <span className="text-muted"> {"14200"}</span>

            </p>

          </div>
        </div>
      </div>

      {/* Outros cards podem ser adicionados seguindo a mesma estrutura */}
    </div>
  );
};

export default DashboardRHStats;
