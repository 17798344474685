/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-undef */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */

import React, { useState, useEffect } from "react";
import { Table } from "antd";
import Breadcrumbs from "../../../../components/Breadcrumbs";

import { useTranslation } from "react-i18next";

import AuthUser from "../../../../components/AuthUser";
import Spinner from "../../../../components/Spinner";

const LivroOcorrencia = () => {
  const [ocorrencias, setOcorrencias] = useState([]);

  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const { getToken, logout } = AuthUser();

  useEffect(() => {
    if (getToken() === undefined) {
      logout();
    } else {
      const fetchData = async () => {
        try {
          setLoading(true);
          await getOcorrenciaSampleData();
          setLoading(false);
        } catch (error) {
          console.error("Erro ao obter dados:", error);
          setLoading(false);
        }
      };

      fetchData();
    }
  }, []);

  const getOcorrenciaSampleData = async () => {
    const sampleData = [
      {
        id: 1,
        cliente: "Cliente A",
        localizacao: "Unidade Central",
        turnoTrabalho: "Noturno",
        data: "16/11/2024",
        horario: "22:00",
        descricao: "Identificada movimentação suspeita na entrada principal.",
      },
      {
        id: 2,
        cliente: "Cliente B",
        localizacao: "Filial Norte",
        turnoTrabalho: "Diurno",
        data: "16/11/2024",
        horario: "11:30",
        descricao: "Falta de energia registrada, acionada equipe técnica.",
      },
      {
        id: 3,
        cliente: "Cliente C",
        localizacao: "Filial Sul",
        turnoTrabalho: "Noturno",
        data: "15/11/2024",
        horario: "23:45",
        descricao: "Colaborador reportou equipamento defeituoso.",
      },
      {
        id: 4,
        cliente: "Cliente D",
        localizacao: "Unidade Oeste",
        turnoTrabalho: "Diurno",
        data: "16/11/2024",
        horario: "09:15",
        descricao: "Visitante sem identificação tentou acesso ao prédio.",
      },
      {
        id: 5,
        cliente: "Cliente E",
        localizacao: "Unidade Leste",
        turnoTrabalho: "Noturno",
        data: "15/11/2024",
        horario: "21:30",
        descricao: "Sistema de câmeras fora do ar por 15 minutos.",
      },
    ];

    setOcorrencias(sampleData);
  };

  const columns = [
    {
      title: t("Nº"),
      dataIndex: "id",
      render: (text) => <span>{text}</span>,
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: t("Cliente"),
      dataIndex: "cliente",
      render: (text) => <span>{text}</span>,
      sorter: (a, b) => a.cliente.localeCompare(b.cliente),
    },
    {
      title: t("Localização"),
      dataIndex: "localizacao",
      render: (text) => <span>{text}</span>,
      sorter: (a, b) => a.localizacao.localeCompare(b.localizacao),
    },
    {
      title: t("Turno de Trabalho"),
      dataIndex: "turnoTrabalho",
      render: (text) => <span>{text}</span>,
      sorter: (a, b) => a.turnoTrabalho.localeCompare(b.turnoTrabalho),
    },
    {
      title: t("Data"),
      dataIndex: "data",
      render: (text) => <span>{text}</span>,
      sorter: (a, b) => a.data.localeCompare(b.data),
    },
    {
      title: t("Horário"),
      dataIndex: "horario",
      render: (text) => <span>{text}</span>,
      sorter: (a, b) => a.horario.localeCompare(b.horario),
    },
    {
      title: t("Descrição da Ocorrência"),
      dataIndex: "descricao",
      render: (text) => <span>{text}</span>,
    },
  ];

  const totalOcorrencias = ocorrencias.length;
  const totalTurnoNoturno = ocorrencias.filter((o) => o.turnoTrabalho === "Noturno").length;
  const totalTurnoDiurno = ocorrencias.filter((o) => o.turnoTrabalho === "Diurno").length;
  const totalClientes = new Set(ocorrencias.map((o) => o.cliente)).size;
  const totalLocalizacoes = new Set(ocorrencias.map((o) => o.localizacao)).size;

  if (loading)
    return (
      <>
        <Spinner></Spinner>
      </>
    );
  else
    return (
      <>
        <div className="page-wrapper">
          {/* Page Content */}
          <div className="content container-fluid">
            {/* Page Header */}
            <Breadcrumbs
              maintitle={t("Livro de Ocorrência")}
              title="Operações"
            />

            {/* Estatísticas */}
            <div className="row">
              <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                <div className="card dash-widget">
                  <div className="card-body">
                    <span className={`dash-widget-icon fa fa-book`} />
                    <div className="dash-widget-info">
                      <h3>{totalOcorrencias}</h3>
                      <span>Total de Ocorrências</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                <div className="card dash-widget">
                  <div className="card-body">
                    <span className={`dash-widget-icon fa fa-moon`} />
                    <div className="dash-widget-info">
                      <h3>{totalTurnoNoturno}</h3>
                      <span>Ocorrências Noturnas</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                <div className="card dash-widget">
                  <div className="card-body">
                    <span className={`dash-widget-icon fa fa-sun`} />
                    <div className="dash-widget-info">
                      <h3>{totalTurnoDiurno}</h3>
                      <span>Ocorrências Diurnas</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                <div className="card dash-widget">
                  <div className="card-body">
                    <span className={`dash-widget-icon fa fa-building`} />
                    <div className="dash-widget-info">
                      <h3>{totalClientes}</h3>
                      <span>Total de Clientes</span>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="table-responsive">
                  <Table
                    columns={columns}
                    dataSource={ocorrencias}
                    rowKey="id"
                    pagination={{ pageSize: 5 }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
};

export default LivroOcorrencia;
