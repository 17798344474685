import React from "react";
import { useTranslation } from "react-i18next";

const DashboardRHValues = ({ dashboardData }) => {
  const { t } = useTranslation();

  const financialData = [
    {
      title: t("Total Valor de Salários"),
      value: dashboardData.totalSalarios || "AKZ 0",
      icon: "fa-money-bill-wave",
    },
    {
      title: t("Salários Pagos"),
      value: dashboardData.totalSalariosPagos || "AKZ 0",
      icon: "fa-check-circle text-success",
    },
    {
      title: t("Salários Não Pagos"),
      value: dashboardData.totalSalariosNaoPagos || "AKZ 0",
      icon: "fa-times-circle text-danger",
    },
    {
      title: t("Total Dívidas Pagas"),
      value: dashboardData.dividas || "AKZ 0",
      icon: "fa-exclamation-circle",
    },
  ]; 

  const formatNumber = (number) => {
    return new Intl.NumberFormat("pt-PT", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(number);
  };

  return (
    <div className="row">
      {financialData.map((item, index) => (
        <div
          key={index}
          className="col-md-6 col-sm-6 col-lg-6 col-xl-3"
        >
          <div className="card dash-widget">
            <div className="card-body">
              <span className={`dash-widget-icon fa ${item.icon}`} />
              <div className="dash-widget-info">
                <h3>{formatNumber(item.value > 0 ? item.value : 0 )}</h3>
                <span>{item.title}</span>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default DashboardRHValues;
