/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Applogo } from "../../../Routes/ImagePath";
import { BackgroundLogin4 } from "../../../Routes/ImagePath";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup.js";
import { useDispatch } from "react-redux";
import { login } from "../../../user";
import { resetFunctionwithlogin } from "../../../components/ResetFunction";
// import { login } from "../../../user";

import AuthUser from "../../../components/AuthUser";
import Spinner from "../../../components/Spinner";

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Please enter a valid email address")
    .required("Email is required"),
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .max(20, "Password must be at most 20 characters")
    .required("Password is required"),
});

const Login = (props) => {

  const { http, setToken } = AuthUser();

  const details = localStorage.getItem("loginDetails");

  const loginData = JSON.parse(details);

  const {
    register,
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [emailError, setEmailError] = useState(false);

  const [email, setEmail] = useState('');
  const [permissaoVer, setPermissaoVer] = useState(9);
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  function refreshPage() {
    window.location.reload(false);
  }


  const onSubmit = async () => {

    localStorage.setItem("colorschema", "orange");
    localStorage.setItem("layout", "vertical");
    localStorage.setItem("layoutwidth", "fixed");
    localStorage.setItem("layoutpos", "fluid");
    localStorage.setItem("topbartheme", "light");
    localStorage.setItem("layoutSized", "lg");
    localStorage.setItem("layoutStyling", "default");
    localStorage.setItem("layoutSidebarStyle", "dark");

    try {

      setLoading(true);

      const res = await http.post('/login', { email: email, password: password });

      toast.success('Credências aceite com sucesso!', {
        position: 'top-right',
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        onClose: () => {

          setToken(res.data.user, res.data.permissoes, res.data.access_token, res.data.empresa, res.data.multipla_visibilidade, res.data.user.missao, res.data.missoes, res.data.contacto);

          setPermissaoVer(res.data.user.nivel_id);

          if (res.data.multipla_visibilidade || res.data.user.tipo == 1)
            navigate("/painel-missao");
          else
            navigate("/admin-dashboard");

        }
      });




      //if (res.data.banca_permissao)
      //props.history.push('/dream-pos/dashboard-banco');
      /*else
          props.history.push('/dream-pos/dashboard');*/

    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error('Credências inválidas, tente novamente!', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      } else {
        toast.error('Algo correu mal, tente novamente mais tarde!', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    } finally {
      setLoading(false);
    }

  };

  useEffect(() => {
    setValue("email", localStorage.getItem("email"));
    setValue("password", localStorage.getItem("password"));
  }, []);

  const [eye, seteye] = useState(true);

  const onEyeClick = () => {
    seteye(!eye);
  };



  {/*
  return (
  <div
    style={{
      backgroundImage: `url(${BackgroundLogin4})`,
      backgroundSize: '100% 100%',  // Estica a imagem para cobrir toda a área da div
      backgroundPosition: 'center',  // Mantém o centro da imagem como ponto de referência
      backgroundRepeat: 'no-repeat', // Impede que a imagem seja repetida
      minHeight: '100vh'
    }}
  >*/}




  return (
    <div style={{ backgroundImage: `url(${BackgroundLogin4})`, backgroundSize: 'cover', backgroundPosition: 'bottom', minHeight: '100vh' }}>

      <div className="account-page">
        <div className="main-wrapper">
          <div className="account-content">
            {/* <Link to="/job-list" className="btn btn-primary apply-btn">
              Aplicar candidatura
            </Link>
            <Link to="/painel-missao" className="btn btn-secondary apply-btn">
              Painel Missão
            </Link> */}
            <div className="container">
              {/* Account Logo */}
              <div className="account-logo">
                <Link to="#">
                  <img src={Applogo} alt="Projetos e RH" />
                </Link>
              </div>
              {/* /Account Logo */}
              <div className="account-box">
                <div className="account-wrapper">
                  <h3 className="account-title">SISGEMA-V2024</h3>
                  <p className="account-subtitle">Accesse ao teu Dashboard</p>
                  {/* Account Form */}
                  <div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="input-block mb-4">
                        <label className="col-form-label">Email</label>
                        <Controller
                          name="email"
                          control={control}
                          render={({ field }) => (
                            <input
                              className={`form-control ${errors?.email ? "error-input" : ""
                                }`}
                              type="text"
                              defaultValue={localStorage.getItem("email")}
                              onChange={e => setEmail(e.target.value)}
                              value={email}
                              autoComplete="true"
                            />
                          )}
                        />

                        <span className="text-danger">
                          {" "}
                          {errors.email?.message}{" "}
                        </span>
                      </div>
                      <div className="input-block mb-4">
                        <div className="row">
                          <div className="col">
                            <label className="col-form-label">Senha</label>
                          </div>
                          {/* <div className="col-auto">
                            <Link className="text-muted" to="/forgot-password">
                              Esqueceu a senha?
                            </Link>
                          </div> */}
                        </div>
                        <div style={{ position: "relative" }}>
                          <Controller
                            name="password"
                            control={control}
                            render={({ field }) => (
                              <input
                                className={`form-control ${errors?.password ? "error-input" : ""
                                  }`}
                                type={eye ? "password" : "text"}
                                defaultValue={localStorage.getItem("password")}
                                value={password}
                                onChange={e => setPassword(e.target.value)}
                              // autoComplete="true"
                              />
                            )}
                          />
                          <span
                            style={{
                              position: "absolute",
                              right: "5%",
                              top: "30%",
                            }}
                            onClick={onEyeClick}
                            className={`fa-solid ${eye ? "fa-eye-slash" : "fa-eye"
                              } `}
                          />
                        </div>
                        <span className="text-danger">
                          {" "}
                          {errors.password?.message}{" "}
                        </span>
                      </div>
                      <div className="input-block text-center">
                        <button
                          className="btn btn-primary account-btn"
                          type="submit"
                        >
                          {loading ? <Spinner /> : 'Entrar'}
                        </button>
                      </div>
                    </form>
                    <div className="account-footer">
                      <p>
                        Esqueceu a senha ?{" "}
                        <Link to="/forgot-password">Clique aqui para recuperar</Link>
                      </p>
                    </div>
                  </div>
                  {/* /Account Form */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />

    </div>
  );
};

export default Login;
