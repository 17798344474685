/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-undef */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */

import React, { useState, useEffect } from "react";
import { Table } from "antd";
import Breadcrumbs from "../../../../components/Breadcrumbs";

import { useTranslation } from "react-i18next";

import AuthUser from "../../../../components/AuthUser";
import Spinner from "../../../../components/Spinner";

const EntregaChaves = () => {
  const [chaves, setChaves] = useState([]);

  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const { getToken, logout } = AuthUser();

  useEffect(() => {
    if (getToken() === undefined) {
      logout();
    } else {
      const fetchData = async () => {
        try {
          setLoading(true);
          await getChavesSampleData();
          setLoading(false);
        } catch (error) {
          console.error("Erro ao obter dados:", error);
          setLoading(false);
        }
      };

      fetchData();
    }
  }, []);

  const getChavesSampleData = async () => {
    const sampleData = [
      {
        id: 1,
        data: "16/11/2024",
        setor: "Administração",
        colaboradorSolicita: "João Silva",
        horarioSaida: "08:30",
        assinaturaSolicitante: "João S.",
        devolvidaPor: "Carlos Mendes",
        horarioDevolucao: "10:45",
      },
      {
        id: 2,
        data: "16/11/2024",
        setor: "Manutenção",
        colaboradorSolicita: "Maria Oliveira",
        horarioSaida: "09:15",
        assinaturaSolicitante: "Maria O.",
        devolvidaPor: "Pedro Santos",
        horarioDevolucao: "11:30",
      },
      {
        id: 3,
        data: "15/11/2024",
        setor: "Segurança",
        colaboradorSolicita: "Carlos Alberto",
        horarioSaida: "10:00",
        assinaturaSolicitante: "Carlos A.",
        devolvidaPor: "Lucas Costa",
        horarioDevolucao: "12:00",
      },
      {
        id: 4,
        data: "15/11/2024",
        setor: "TI",
        colaboradorSolicita: "Fernanda Lima",
        horarioSaida: "11:30",
        assinaturaSolicitante: "Fernanda L.",
        devolvidaPor: "Roberta Almeida",
        horarioDevolucao: "14:00",
      },
      {
        id: 5,
        data: "14/11/2024",
        setor: "Recursos Humanos",
        colaboradorSolicita: "Ricardo Mendes",
        horarioSaida: "08:00",
        assinaturaSolicitante: "Ricardo M.",
        devolvidaPor: "Ana Paula",
        horarioDevolucao: "09:45",
      },
    ];

    setChaves(sampleData);
  };

  const columns = [
    {
      title: t("ID"),
      dataIndex: "id",
      render: (text) => <span>{text}</span>,
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: t("Data"),
      dataIndex: "data",
      render: (text) => <span>{text}</span>,
      sorter: (a, b) => a.data.localeCompare(b.data),
    },
    {
      title: t("Setor"),
      dataIndex: "setor",
      render: (text) => <span>{text}</span>,
      sorter: (a, b) => a.setor.localeCompare(b.setor),
    },
    {
      title: t("Colaborador que Solicita"),
      dataIndex: "colaboradorSolicita",
      render: (text) => <span>{text}</span>,
      sorter: (a, b) => a.colaboradorSolicita.localeCompare(b.colaboradorSolicita),
    },
    {
      title: t("Horário de Saída"),
      dataIndex: "horarioSaida",
      render: (text) => <span>{text}</span>,
      sorter: (a, b) => a.horarioSaida.localeCompare(b.horarioSaida),
    },
    {
      title: t("Assinatura do Solicitante"),
      dataIndex: "assinaturaSolicitante",
      render: (text) => <span>{text}</span>,
    },
    {
      title: t("Devolvida Por"),
      dataIndex: "devolvidaPor",
      render: (text) => <span>{text}</span>,
    },
    {
      title: t("Horário da Devolução"),
      dataIndex: "horarioDevolucao",
      render: (text) => <span>{text}</span>,
      sorter: (a, b) => a.horarioDevolucao.localeCompare(b.horarioDevolucao),
    },
  ];

  const totalRegistros = chaves.length;
  const totalDevolvidas = chaves.filter((c) => c.devolvidaPor).length;
  const totalSetores = new Set(chaves.map((c) => c.setor)).size;
  const totalColaboradores = new Set(chaves.map((c) => c.colaboradorSolicita)).size;

  if (loading)
    return (
      <>
        <Spinner></Spinner>
      </>
    );
  else
    return (
      <>
        <div className="page-wrapper">
          {/* Page Content */}
          <div className="content container-fluid">
            {/* Page Header */}
            <Breadcrumbs
              maintitle={t("Entrega de Chaves")}
              title="Operações"
            />

            {/* Estatísticas */}
            <div className="row">
              <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                <div className="card dash-widget">
                  <div className="card-body">
                    <span className={`dash-widget-icon fa fa-key`} />
                    <div className="dash-widget-info">
                      <h3>{totalRegistros}</h3>
                      <span>Total de Registros</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                <div className="card dash-widget">
                  <div className="card-body">
                    <span className={`dash-widget-icon fa fa-ticket`} />
                    <div className="dash-widget-info">
                      <h3>{totalDevolvidas}</h3>
                      <span>Chaves Devolvidas</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                <div className="card dash-widget">
                  <div className="card-body">
                    <span className={`dash-widget-icon fa fa-list`} />
                    <div className="dash-widget-info">
                      <h3>{totalSetores}</h3>
                      <span>Total de Setores</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                <div className="card dash-widget">
                  <div className="card-body">
                    <span className={`dash-widget-icon fa fa-user`} />
                    <div className="dash-widget-info">
                      <h3>{totalColaboradores}</h3>
                      <span>Colaboradores</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="table-responsive">
                  <Table
                    columns={columns}
                    dataSource={chaves}
                    rowKey="id"
                    pagination={{ pageSize: 5 }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
};

export default EntregaChaves;
