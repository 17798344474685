/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Applogo } from "../../../Routes/ImagePath";

import AuthUser from "../../../components/AuthUser";
import Spinner from "../../../components/Spinner";

import { toast, ToastContainer } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css';

const ForgotPassword = () => {

  const { http, setToken } = AuthUser();

  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate(); 

  useEffect(() => {
    setEmail("");
  }, []);


  const onSubmit = async () => {

    try {

      setLoading(true);

      if (!validateEmail(email)) {
        setLoading(false);

        // Show a error toast
        toast.error("O email deve ser válido.", {
          position: "top-right",
          autoClose: 3000, // Close the toast after 3 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
        });

        return;
      }

      const res = await http.post('/resetar-senha', { email: email });

      if(res){

        toast.success(res.data?.message, {
          position: 'top-right',
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          onClose: () => {
  
             
  
          }
        });

      }
     

    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error('E-mail inválido, tente novamente!', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      } else {
        toast.error('Algo correu mal, tente novamente mais tarde!', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    } finally {
      setLoading(false);
    }

  };

  const validateEmail = (email) => {
    // Use a regular expression for basic email validation
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  return (
    <div className="account-page">
      <div className="main-wrapper">
        
        <div className="account-content">
          <div className="container">
            <div className="account-logo">
              <Link to="/app/main/dashboard">
                <img src={Applogo} alt="" />
              </Link>
            </div>
            <div className="account-box">
              <div className="account-wrapper">
                <h3 className="account-title">Esqueceu a senha?</h3>
                <p className="account-subtitle">
                  Digite o seu e-mail para receber uma nova senha
                </p>
                  <div className="input-block">
                    <label>Email</label>
                    <input className="form-control" type="text" onChange={e => setEmail(e.target.value)}
                              value={email} />
                  </div>
                  <div className="input-block text-center">
                  <button
                          className="btn btn-primary account-btn"
                          onClick={()=>onSubmit()}
                        >
                          {loading ? <Spinner /> : 'Resetar senha'}
                        </button>
                  </div>
                  <div className="account-footer">
                    <p>
                      Voltar ao login <Link to="/">aqui</Link>
                    </p>
                  </div> 
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />

    </div>
  );
};
export default ForgotPassword;
