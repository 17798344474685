/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-undef */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */

import React, { useState, useEffect } from "react";
import { Table } from "antd";
import Breadcrumbs from "../../../../components/Breadcrumbs";

import { useTranslation } from "react-i18next";

import AuthUser from "../../../../components/AuthUser";
import Spinner from "../../../../components/Spinner";

const Supervisao24Horas = () => {
  const [atividades, setAtividades] = useState([]);

  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const { getToken, logout } = AuthUser();

  useEffect(() => {
    if (getToken() === undefined) {
      logout();
    } else {
      const fetchData = async () => {
        try {
          setLoading(true);
          await getAtividadesSampleData();
          setLoading(false);
        } catch (error) {
          console.error("Erro ao obter dados:", error);
          setLoading(false);
        }
      };

      fetchData();
    }
  }, []);

  const getAtividadesSampleData = async () => {
    const sampleData = [
      {
        id: 1,
        data: "16/11/2024",
        hora: "08:30",
        supervisor: "João Silva",
        posto: "Posto 1",
        cliente: "Cliente A",
        efetivoPresente: 3,
        ocorrencias: "Movimentação suspeita registrada.",
        situacaoOperativa: "Em operação normal.",
      },
      {
        id: 2,
        data: "16/11/2024",
        hora: "09:15",
        supervisor: "Maria Oliveira",
        posto: "Posto 2",
        cliente: "Cliente B",
        efetivoPresente: 4,
        ocorrencias: "Equipamento defeituoso reportado.",
        situacaoOperativa: "Aguardando reparo do equipamento.",
      },
      {
        id: 3,
        data: "15/11/2024",
        hora: "10:00",
        supervisor: "Carlos Santos",
        posto: "Posto 3",
        cliente: "Cliente C",
        efetivoPresente: 2,
        ocorrencias: "Visitante não identificado tentando acesso.",
        situacaoOperativa: "Segurança reforçada.",
      },
      {
        id: 4,
        data: "15/11/2024",
        hora: "11:30",
        supervisor: "Fernanda Lima",
        posto: "Posto 4",
        cliente: "Cliente D",
        efetivoPresente: 5,
        ocorrencias: "Falta de energia por 30 minutos.",
        situacaoOperativa: "Gerador ativado com sucesso.",
      },
      {
        id: 5,
        data: "14/11/2024",
        hora: "07:45",
        supervisor: "Roberto Costa",
        posto: "Posto 5",
        cliente: "Cliente E",
        efetivoPresente: 3,
        ocorrencias: "Nenhuma ocorrência registrada.",
        situacaoOperativa: "Operação sem alterações.",
      },
    ];

    setAtividades(sampleData);
  };

  const columns = [
    {
      title: t("ID"),
      dataIndex: "id",
      render: (text) => <span>{text}</span>,
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: t("Data"),
      dataIndex: "data",
      render: (text) => <span>{text}</span>,
      sorter: (a, b) => a.data.localeCompare(b.data),
    },
    {
      title: t("Hora"),
      dataIndex: "hora",
      render: (text) => <span>{text}</span>,
      sorter: (a, b) => a.hora.localeCompare(b.hora),
    },
    {
      title: t("Quem Supervisionou"),
      dataIndex: "supervisor",
      render: (text) => <span>{text}</span>,
      sorter: (a, b) => a.supervisor.localeCompare(b.supervisor),
    },
    {
      title: t("Posto"),
      dataIndex: "posto",
      render: (text) => <span>{text}</span>,
      sorter: (a, b) => a.posto.localeCompare(b.posto),
    },
    {
      title: t("Cliente"),
      dataIndex: "cliente",
      render: (text) => <span>{text}</span>,
      sorter: (a, b) => a.cliente.localeCompare(b.cliente),
    },
    {
      title: t("Efetivo Presente"),
      dataIndex: "efetivoPresente",
      render: (text) => <span>{text}</span>,
      sorter: (a, b) => a.efetivoPresente - b.efetivoPresente,
    },
    {
      title: t("Ocorrências Registradas"),
      dataIndex: "ocorrencias",
      render: (text) => <span>{text}</span>,
    },
    {
      title: t("Situação Operativa"),
      dataIndex: "situacaoOperativa",
      render: (text) => <span>{text}</span>,
    },
  ];

  const totalSupervisoes = atividades.length;
  const totalOcorrencias = atividades.filter((a) => a.ocorrencias && a.ocorrencias !== "Nenhuma ocorrência registrada.").length;
  const totalSupervisores = new Set(atividades.map((a) => a.supervisor)).size;
  const totalPostos = new Set(atividades.map((a) => a.posto)).size;

  if (loading) return (<><Spinner></Spinner></>); else return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Breadcrumbs maintitle={t("Supervisão 24 Horas")} title="Operações" />

          {/* Estatísticas */}
          <div className="row">
            <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
              <div className="card dash-widget">
                <div className="card-body">
                  <span className={`dash-widget-icon fa fa-shield`} />
                  <div className="dash-widget-info">
                    <h3>{totalSupervisoes}</h3>
                    <span>Total de Supervisões</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
              <div className="card dash-widget">
                <div className="card-body">
                  <span className={`dash-widget-icon fa fa-exclamation-circle`} />
                  <div className="dash-widget-info">
                    <h3>{totalOcorrencias}</h3>
                    <span>Total de Ocorrências</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
              <div className="card dash-widget">
                <div className="card-body">
                  <span className={`dash-widget-icon fa fa-users`} />
                  <div className="dash-widget-info">
                    <h3>{totalSupervisores}</h3>
                    <span>Total de Supervisores</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
              <div className="card dash-widget">
                <div className="card-body">
                  <span className={`dash-widget-icon fa fa-building`} />
                  <div className="dash-widget-info">
                    <h3>{totalPostos}</h3>
                    <span>Total de Postos</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                <Table
                  columns={columns}
                  dataSource={atividades}
                  rowKey="id"
                  pagination={{ pageSize: 5 }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Supervisao24Horas;
