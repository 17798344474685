import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import AuthUser from "../AuthUser";
import { toast, ToastContainer } from "react-toastify";
import Spinner from "../Spinner";

import Swal from "sweetalert2";

import { startOfMonth, endOfMonth, format } from "date-fns";

const AlterarMesEAnoModal = ({ onSubmitSuccess }) => {
  // eslint-disable-next-line no-unused-vars
  const {
    http,
    url_root,
    user,
    user_permissoes,
    verificarPermissao,
    getMissao,
  } = AuthUser();

  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState("");
  const [tipoMarcacao, setTipoMarcacao] = useState(1);
  const [banco_id, setBancoID] = useState(-1);

  const currentDate = new Date();

  const [mes, setMes] = useState(format(currentDate, "MM"));
  const [ano, setAno] = useState(format(currentDate, "yyyy"));

  const toggleLoading = (value) => {
    setLoading(value);
  };

  useEffect(() => {
    const currentDate = new Date();
    setData(format(currentDate, "yyyy-MM-dd"));
  }, []);

  // REGISTO NOVO

  const marcar_alteracao = async () => {
    try {
      toggleLoading(true);
      await registar();
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Show a error toast
        toast.error("Utilizador não autenticado.", {
          position: "top-right",
          autoClose: 3000, // Close the toast after 3 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
        });
      } else {
        // Show a error toast
        toast.error(
          "Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado",
          {
            position: "top-right",
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          }
        );
      }
    } finally {
      toggleLoading(false);
    }
  };

  const registar = async () => {
    try {

      const response = { mes: mes, ano: ano };

      triggerCloseButtonClick('closeButton20');

      onSubmitSuccess(response);

    } catch (error) {
      toast.error(
        "Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.",
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
        }
      );
    }
  };

  const triggerCloseButtonClick = (buttonId) => {

    const closeButton = document.getElementById(buttonId);
    if (closeButton) {
      closeButton.dispatchEvent(new Event('click'));
    }

  };

  return (
    <>
      {/* Personal Info Modal */}
      <div
        id="alterar_mes_ano_modal"
        className="modal custom-modal fade"
        role="dialog"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                <i className="fa fa-calendar" /> Alterar Mes E Ano
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="closeButton20"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>

            <div className="modal-body">
              <div className="card">
                <div className="card-body">
                  <form>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="input-block mb-3 form-focus focused">
                          <label className="focus-label">{t("Mes")}</label>

                          <select
                            onChange={(e) => setMes(e.target.value)}
                            value={mes}
                            className="form-control select"
                          >
                            <option value="1">Janeiro</option>
                            <option value="2">Fevereiro</option>
                            <option value="3">Março</option>
                            <option value="4">Abril</option>
                            <option value="5">Maio</option>
                            <option value="6">Junho</option>
                            <option value="7">Julho</option>
                            <option value="8">Agosto</option>
                            <option value="9">Setembro</option>
                            <option value="10">Outubro</option>
                            <option value="11">Novembro</option>
                            <option value="12">Dezembro</option>
                          </select>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="input-block mb-3 form-focus focused">
                          <label className="focus-label">{t("Ano")}</label>

                          <select
                            onChange={(e) => setAno(e.target.value)}
                            value={ano}
                            className="form-control select"
                          >
                            <option value="2023">2023</option>
                            <option value="2024">2024</option>
                            <option value="2025">2025</option>
                          </select>
                        </div>
                      </div>

                      {/* estaaqui */}

                      {/* terminaaqui */}
                    </div>

                    <div className="submit-section m-t-20 text-center">
                      <a
                        className="btn btn-primary submit-btn"
                        onClick={marcar_alteracao}
                      >
                        {t("Alterar")}
                      </a>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Personal Info Modal */}

      <ToastContainer />

      {loading ? (
        <>
          <Spinner></Spinner>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default AlterarMesEAnoModal;
