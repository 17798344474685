/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-undef */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */

import React, { useState, useEffect } from "react";
import { Table } from "antd";
import Breadcrumbs from "../../../../components/Breadcrumbs";

import { useTranslation } from "react-i18next";

import AuthUser from "../../../../components/AuthUser";
import Spinner from "../../../../components/Spinner";

const VeiculoEntraSaida = () => {
  const [vehicles, setVehicles] = useState([]);

  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const { getToken, logout } = AuthUser();

  useEffect(() => {
    if (getToken() === undefined) {
      logout();
    } else {
      const fetchData = async () => {
        try {
          setLoading(true);
          await getVehiclesSampleData();
          setLoading(false);
        } catch (error) {
          console.error("Erro ao obter dados:", error);
          setLoading(false);
        }
      };

      fetchData();
    }
  }, []);

  const getVehiclesSampleData = async () => {
    const sampleData = [
      {
        id: 1,
        data: "16/11/2024",
        placa: "ABC-1234",
        motorista: "João Silva",
        origem: "Filial A",
        destino: "Filial B",
        horarioEntrada: "08:00",
        horarioSaida: "10:00",
        liberadoPor: "Carlos Mendes",
        vistoVigilante: "Sim",
      },
      {
        id: 2,
        data: "16/11/2024",
        placa: "XYZ-5678",
        motorista: "Maria Oliveira",
        origem: "Filial C",
        destino: "Filial D",
        horarioEntrada: "09:30",
        horarioSaida: "12:00",
        liberadoPor: "Ana Paula",
        vistoVigilante: "Não",
      },
      {
        id: 3,
        data: "16/11/2024",
        placa: "DEF-9012",
        motorista: "Pedro Santos",
        origem: "Filial B",
        destino: "Filial E",
        horarioEntrada: "07:45",
        horarioSaida: "11:15",
        liberadoPor: "Lucas Costa",
        vistoVigilante: "Sim",
      },
      {
        id: 4,
        data: "16/11/2024",
        placa: "GHI-3456",
        motorista: "Carlos Alberto",
        origem: "Filial F",
        destino: "Filial G",
        horarioEntrada: "10:15",
        horarioSaida: "13:45",
        liberadoPor: "Ricardo Silva",
        vistoVigilante: "Sim",
      },
      {
        id: 5,
        data: "16/11/2024",
        placa: "JKL-7890",
        motorista: "Fernanda Lima",
        origem: "Filial H",
        destino: "Filial I",
        horarioEntrada: "08:30",
        horarioSaida: "10:45",
        liberadoPor: "Roberta Almeida",
        vistoVigilante: "Sim",
      },
    ];

    setVehicles(sampleData);
  };

  const columns = [
    {
      title: t("Nº"),
      dataIndex: "id",
      render: (text) => <span>{text}</span>,
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: t("Data"),
      dataIndex: "data",
      render: (text) => <span>{text}</span>,
      sorter: (a, b) => a.data.localeCompare(b.data),
    },
    {
      title: t("Placa/Matrícula"),
      dataIndex: "placa",
      render: (text) => <span>{text}</span>,
      sorter: (a, b) => a.placa.localeCompare(b.placa),
    },
    {
      title: t("Nome do Motorista"),
      dataIndex: "motorista",
      render: (text) => <span>{text}</span>,
      sorter: (a, b) => a.motorista.localeCompare(b.motorista),
    },
    {
      title: t("Origem"),
      dataIndex: "origem",
      render: (text) => <span>{text}</span>,
      sorter: (a, b) => a.origem.localeCompare(b.origem),
    },
    {
      title: t("Destino"),
      dataIndex: "destino",
      render: (text) => <span>{text}</span>,
      sorter: (a, b) => a.destino.localeCompare(b.destino),
    },
    {
      title: t("Horário de Entrada"),
      dataIndex: "horarioEntrada",
      render: (text) => <span>{text}</span>,
      sorter: (a, b) => a.horarioEntrada.localeCompare(b.horarioEntrada),
    },
    {
      title: t("Horário de Saída"),
      dataIndex: "horarioSaida",
      render: (text) => <span>{text}</span>,
      sorter: (a, b) => a.horarioSaida.localeCompare(b.horarioSaida),
    },
    {
      title: t("Liberado Por"),
      dataIndex: "liberadoPor",
      render: (text) => <span>{text}</span>,
      sorter: (a, b) => a.liberadoPor.localeCompare(b.liberadoPor),
    },
    {
      title: t("Visto do Vigilante"),
      dataIndex: "vistoVigilante",
      render: (text) => <span>{text}</span>,
      sorter: (a, b) => a.vistoVigilante.localeCompare(b.vistoVigilante),
    },
  ];

  const totalRegistros = vehicles.length;
  const totalVigilanteNao = vehicles.filter(
    (v) => v.vistoVigilante === "Não"
  ).length;
  const totalMotoristas = new Set(vehicles.map((v) => v.motorista)).size;
  const totalLiberados = vehicles.filter((v) => v.liberadoPor).length;

  if (loading)
    return (
      <>
        <Spinner></Spinner>
      </>
    );
  else
    return (
      <>
        <div className="page-wrapper">
          {/* Page Content */}
          <div className="content container-fluid">
            {/* Page Header */}
            <Breadcrumbs
              maintitle={t("Veículos Entrada e Saída")}
              title="Operações"
            />

            <div className="row">
              <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                <div className="card dash-widget">
                  <div className="card-body">
                    <span className={`dash-widget-icon fa fa-car`} />
                    <div className="dash-widget-info">
                      <h3>{totalRegistros}</h3>
                      <span>Total</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                <div className="card dash-widget">
                  <div className="card-body">
                    <span className={`dash-widget-icon fa fa-times-circle`} />
                    <div className="dash-widget-info">
                      <h3>{totalVigilanteNao}</h3>
                      <span>Sem vistos</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                <div className="card dash-widget">
                  <div className="card-body">
                    <span className={`dash-widget-icon fa fa-users`} />
                    <div className="dash-widget-info">
                      <h3>{totalMotoristas}</h3>
                      <span>Total de Motoristas</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                <div className="card dash-widget">
                  <div className="card-body">
                    <span className={`dash-widget-icon fa fa-ticket`} />
                    <div className="dash-widget-info">
                      <h3>{totalLiberados}</h3>
                      <span>Registros Liberados</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="table-responsive">
                  <Table
                    columns={columns}
                    dataSource={vehicles}
                    rowKey="id"
                    pagination={{ pageSize: 5 }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
};

export default VeiculoEntraSaida;
