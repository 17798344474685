/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-undef */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */

import React, { useEffect, useState } from "react";
import axios from "axios";
import Charts from "./charts";
import Reports from "./Reports";
import Statistics from "./statistics";
import InvoiceTable from "./invoiceTable";
import PaymentTable from "./paymentTable";
import ClientTable from "./clientTable";
import RecentTable from "./recentTable";
import Breadcrumbs from "../../../../../components/Breadcrumbs";
import { base_url } from "../../../../../base_urls";
import { useTranslation } from "react-i18next";

import { Link, useParams, useNavigate } from 'react-router-dom';

import {
  Applogo,
  Avatar_02,
  headerlogo,
  lnEnglish,
  lnAngola,
  lnFrench,
  lnGerman,
  lnSpanish
} from "../../../../../Routes/ImagePath";
import MapChart from "./MapChart";


import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";


import { format } from 'date-fns';

import Swal from "sweetalert2";

import AuthUser from "../../../../../components/AuthUser";
import { toast, ToastContainer } from 'react-toastify';
import Spinner from "../../../../../components/Spinner";
import { Projection } from "leaflet";
import TodasTarefasModalPopup from "../../../../../components/modelpopup/TodasTarefasModalPopup";

const AdminDashboard = () => {
  const [users, setUsers] = useState([]);

  const navigate = useNavigate();

  // eslint-disable-next-line no-unused-vars
  const { http, url_root, user_permissoes, verificarPermissao, getToken, user, getMissao } = AuthUser();

  const [dashboard_info, setDashboardInfo] = useState({});
  const [filteredActividades, setFilteredActivities] = useState([]);

  // State to track whether the "checkgeral" checkbox is checked
  const [loading, setLoading] = useState(false);

  const [flagImage, setFlagImage] = useState(lnAngola);

  const { t, i18n } = useTranslation();

  const [totalsByMonth, setTotalsByMonth] = useState({});

  //const totalsByMonth = {};

  const changeLanguage = (lng) => {

    console.log(`Changing language to ${lng}`);
    i18n.changeLanguage(lng).then(() => {
      console.log(`Language changed successfully to ${lng}`);
    });

    // Debugging statement
    i18n.changeLanguage(lng);
    setFlagImage(
      lng == "en"
        ? lnEnglish
        : lng == "fr"
          ? lnFrench
          : lng == "es"
            ? lnSpanish :
            lng == "pt"
              ? lnAngola
              : lnGerman
    );
  };

  // eslint-disable-next-line
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        await getDashaboardInfo();
      } catch (error) {
        console.error('Erro ao obter dados do dashboard:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {

    const updatedTotalsByMonth = {};

    const filteredProjects = dashboard_info?.projetos?.filter(project => {

      // Excluir se a missão do projeto é diferente da missão na sessão
      if (getMissao()?.id != project?.missao_id) {
        return false;
      }

      // Excluir se o projeto esta arquivado
      if (project?.estado == 2) {
        return false;
      }

      // Se o tipo de usuário for 1, retorna true para todos os projetos
      if (user.tipo == 1) {
        return true;
      }

      // Se o projeto foi criado pelo usuário
      if (project?.criado_por == user.id) {
        return true;
      }

      // Se o usuário é participante do projeto
      const participante = project?.colaboradores.find(colaborador => colaborador.colaborador_id == user.id);
      if (participante) {
        return true;
      }

      return false;

    }) || [];

    // Calcula os totais por mês
    filteredProjects.forEach(project => {
      const { custo, orcamento, data_inicio } = project;
      const month = new Date(data_inicio).toLocaleString('default', { month: 'short' });

      updatedTotalsByMonth[month] = {
        "Plano": (updatedTotalsByMonth[month]?.["Plano"] || 0) + orcamento,
        "Custo Real": (updatedTotalsByMonth[month]?.["Custo Real"] || 0) + custo
      };
    });

    // Update totalsByMonth state with the calculated totals
    setTotalsByMonth(updatedTotalsByMonth);

    console.log('filteredProjects', filteredProjects);

    // Filtra as atividades de acordo com os projetos filtrados
    const filteredActivities = dashboard_info?.atividades?.filter(activity => {
      return filteredProjects.some(project => project.id == activity.projeto_id);
    });

    // Atualiza o estado com as atividades filtradas
    setFilteredActivities(filteredActivities);

    console.log('filteredActivities', filteredActivities);

  }, [dashboard_info]);

  const getDashaboardInfo = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get('/dashboard/projetos/' + getMissao().id);

      setDashboardInfo(res.data);

      console.log('Dashboard', res.data);

      setLoading(false);

    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const handleNavegarParaTarefa = (tarefa_id) => {
    navigate('/task/tasks/' + tarefa_id);
  };

  // Verifique se dashboard_info.tickets é um array antes de aplicar métodos de array
  const tickets = Array.isArray(dashboard_info?.tickets) ? dashboard_info?.tickets : [];


  if (loading) return (<><Spinner></Spinner></>); else return (
    <div className="main-wrapper">
      <div className="page-wrapper">
        <div className="content container-fluid">
          {/* Page Header */}
          <Breadcrumbs maintitle={t("Welcome") + ', ' + user?.name} title="Dashboard" />
          {/* /Page Header */}
          <div className="row">

            <div
              className="col-md-6 col-sm-6 col-lg-6 col-xl-3"
            >
              <div className="card dash-widget">
                <div className="card-body">
                  <span className={`dash-widget-icon fa fa-cubes`} />
                  <div className="dash-widget-info">
                    <h3>
                      {dashboard_info?.projetos?.filter(project => {

                        // Excluir se a missão do projeto é diferente da missão na sessão
                        if (getMissao()?.id != project.missao_id) {
                          return false;
                        }

                        // Excluir se o projeto esta arquivado
                        if (project.estado == 2) {
                          return false;
                        }

                        // Se o tipo de usuário for 1, retorna true para todos os projetos
                        if (user.tipo == 1) {
                          return true;
                        }

                        // Se o projeto foi criado pelo usuário
                        if (project.criado_por == user.id) {
                          return true;
                        }

                        // Se o usuário é participante do projeto
                        const participante = project.colaboradores.find(colaborador => colaborador.colaborador_id == user.id);
                        if (participante) {
                          return true;
                        }

                        return false;

                      }).length}
                    </h3>
                    <span>{t('Project')}</span>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col-md-6 col-sm-6 col-lg-6 col-xl-3"
            >
              <div className="card dash-widget">
                <div className="card-body">
                  <span className={`dash-widget-icon fa fa-ticket`} />
                  <div className="dash-widget-info">
                    <h3>{dashboard_info?.tickets?.filter(project => {

                      // Excluir se a missão do projeto é diferente da missão na sessão
                      if (getMissao()?.id != project.missao_id) {
                        return false;
                      }

                      // Excluir se o projeto esta arquivado
                      if (project.estado == 2) {
                        return false;
                      }

                      // Se o tipo de usuário for 1, retorna true para todos os projetos
                      if (user.tipo == 1) {
                        return true;
                      }

                      // Se o projeto foi criado pelo usuário
                      if (project.criado_por == user.id) {
                        return true;
                      }

                      // Se o usuário é participante do projeto
                      const participante = project.colaboradores.find(colaborador => colaborador.colaborador_id == user.id);
                      if (participante) {
                        return true;
                      }

                      return false;

                    }).length}</h3>
                    <span>{t('tickets')}</span>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col-md-6 col-sm-6 col-lg-6 col-xl-3"
            >
              <div className="card dash-widget">
                <div className="card-body">
                  <span className={`dash-widget-icon fa fa-list`} />
                  <div className="dash-widget-info">
                    <h3>
                      {dashboard_info?.tarefas?.filter(project => {

                        // Excluir se a missão do projeto é diferente da missão na sessão
                        if (getMissao()?.id != project?.projeto?.missao_id) {
                          return false;
                        }

                        // Excluir se o projeto esta arquivado
                        if (project?.projeto?.estado == 2) {
                          return false;
                        }

                        // Se o tipo de usuário for 1, retorna true para todos os projetos
                        if (user.tipo == 1) {
                          return true;
                        }

                        // Se o projeto foi criado pelo usuário
                        if (project?.projeto?.criado_por == user.id) {
                          return true;
                        }

                        // Se o usuário é participante do projeto
                        const participante = project?.projeto?.colaboradores.find(colaborador => colaborador.colaborador_id == user.id);
                        if (participante) {
                          return true;
                        }

                        return false;

                      }).length}

                    </h3>
                    <span>{t('tasks')}</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
              <div className="card dash-widget">
                <div className="card-body">
                  <span className={`dash-widget-icon fa fa-user`} />
                  <div className="dash-widget-info">
                    <h3>
                      {dashboard_info?.colaboradores?.filter(colaborador => {

                        // Excluir se a missão do projeto é diferente da missão na sessão
                        if (getMissao()?.id == colaborador?.missao_id) {
                          return true;
                        }

                        return false;

                      }).length}

                    </h3>
                    <span>{t('employees')}</span>
                  </div>
                </div>
              </div>
            </div>

          </div>
          {/* /Charts */}
          <Charts atividades={filteredActividades} projetos={dashboard_info?.projetos} tickets={dashboard_info?.tickets} />
          {/* /Charts */}
          {/* <Reports /> */}
          <Statistics projetos={dashboard_info?.projetos} tarefas={dashboard_info?.tarefas} tickets={dashboard_info?.tickets} />



          <div className="row">
            <div className="col-md-12 text-start">
              <div className="card">
                <div className="card-body">
                  <h3 className="card-title">{t('Tasks')}</h3>

                  <div className="activity">

                    {' '}
                    <span
                      data-bs-toggle="modal"
                      data-bs-target="#all_tasks_modal"
                      id="trigger_modal_all_task"
                      className="btn btn-secondary mb-2 ml-2"
                    >
                      <i className="fa fa-list"></i>{' '}
                      {t('All Tasks')}
                    </span>

                    <ul className="nav nav-tabs nav-tabs-top nav-justified mb-0">

                      <li className="nav-item">
                        <Link
                          className="nav-link active"
                          to="#all_tasks"
                          data-bs-toggle="tab"
                          aria-expanded="true">
                          {t('All Tasks')}
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to="#open_tasks"
                          data-bs-toggle="tab"
                          aria-expanded="false">
                          {t('Progress')}
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to="#pending_tasks"
                          data-bs-toggle="tab"
                          aria-expanded="false">
                          {t('Pending Tasks')}
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to="#completed_tasks"
                          data-bs-toggle="tab"
                          aria-expanded="false">
                          {t('Completed Tasks')}
                        </Link>
                      </li>



                    </ul>

                    
                    <div className="tab-content">
                      <div className="tab-pane show active" id="all_tasks">
                        <div className="task-list-body">

                          <table className="table table-bordered" width="100%">
                            <tbody>

                              {dashboard_info?.tarefas?.filter(project => {

                                // Excluir se a missão do projeto é diferente da missão na sessão
                                if (getMissao()?.id != project?.projeto?.missao_id) {
                                  return false;
                                }

                                // Excluir se o projeto esta arquivado
                                if (project?.projeto?.estado == 2) {
                                  return false;
                                }

                                // Se o tipo de usuário for 1, retorna true para todos os projetos
                                if (user.tipo == 1) {
                                  return true;
                                }

                                // Se o projeto foi criado pelo usuário
                                if (project?.projeto?.criado_por == user.id) {
                                  return true;
                                }

                                // Se o usuário é participante do projeto
                                const participante = project?.projeto?.colaboradores.find(colaborador => colaborador.colaborador_id == user.id);
                                if (participante) {
                                  return true;
                                }

                                return false;

                              }).slice(0, 7).map(task => (

                                <tr key={task?.id}>

                                  <td>
                                    <span className="task-label text-start" >
                                      {format(new Date(task?.prazo), 'dd/MM/yyyy')}
                                    </span>
                                  </td>
                                  <td>
                                    {
                                      task?.estado == 1 ? <>
                                        <span
                                          className={"badge bg-inverse-success"}
                                        >
                                          <i className="fa fa-check"></i> <span>{t('Closed')}</span>
                                        </span></> : ''
                                    }

                                    {

                                      ((new Date(task?.prazo) < new Date()) && task?.estado != 1) ? (<><span
                                        className={"badge bg-inverse-danger"}
                                      >
                                        <i className="fa fa-clock"></i> <span>{t('Pending')}</span>
                                      </span></>) : (task?.estado != 1 ? (<span
                                        className={"badge bg-inverse-warning"}
                                      >
                                        <i className="fa fa-list"></i> <span>{t('Opened')}</span>
                                      </span>) : '')
                                    }

                                  </td>
                                  <td>
                                    <span
                                      className={
                                        task?.prioridade == 1
                                          ? "badge bg-inverse-success"
                                          : task?.prioridade == 2 ? "badge bg-inverse-warning" : "badge bg-inverse-danger"
                                      }
                                    >{t('Priority')}{' : '}
                                      {task?.prioridade == 1 ? t('Low') : task?.prioridade == 2 ? t('Medium') : t('High')}
                                    </span>
                                  </td>
                                  <td>
                                    <span
                                      className={"task-label text-start"}
                                    >[{(Math.round(((task?.items?.filter(tarefa => tarefa.estado == 1).length) / task?.items?.length) * 100)) ? (Math.round(((task?.items?.filter(tarefa => tarefa.estado == 1).length) / task?.items?.length) * 100)) : 0}%]
                                    </span>
                                  </td>
                                  <td>
                                    <Link className="action-circle large delete-btn" title="Delete Task" to={"/task/tasks/" + task?.id} >
                                      <span className="task-label" >{task?.nome}
                                      </span>
                                    </Link>
                                  </td>
                                  <td>

                                    <span className="task-action-btn task-btn-right">
                                      <Link className="action-circle large delete-btn" title="Delete Task" to={"/task/tasks/" + task?.id} >
                                        <i className="fa fa-link"></i>
                                      </Link>

                                    </span>


                                  </td>
                                </tr>

                              ))}

                            </tbody>
                          </table>


                        </div>
                      </div>
                      <div className="tab-pane" id="open_tasks">
                        <div className="task-list-body">

                          <table className="table table-bordered" width="100%">
                            <tbody>

                              {dashboard_info?.tarefas?.filter(project => {

                                // Excluir se a missão do projeto é diferente da missão na sessão
                                if (getMissao()?.id != project?.projeto?.missao_id) {
                                  return false;
                                }

                                // Excluir se o projeto esta arquivado
                                if (project?.projeto?.estado == 2) {
                                  return false;
                                }

                                // Excluir se o tarefa esta não concluida
                                if (project?.estado != 0) {
                                  return false;
                                }

                                // Se o tipo de usuário for 1, retorna true para todos os projetos
                                if (user.tipo == 1) {
                                  return true;
                                }

                                // Se o projeto foi criado pelo usuário
                                if (project?.projeto?.criado_por == user.id) {
                                  return true;
                                }

                                // Se o usuário é participante do projeto
                                const participante = project?.projeto?.colaboradores.find(colaborador => colaborador.colaborador_id == user.id);
                                if (participante) {
                                  return true;
                                }

                                return false;

                              }).slice(0, 7)
                                .map(task => (

                                  <tr key={task?.id}>

                                    <td>
                                      <span className="task-label text-start" >
                                        {format(new Date(task?.prazo), 'dd/MM/yyyy')}
                                      </span>
                                    </td>
                                    <td>
                                      {
                                        task?.estado == 1 ? <>
                                          <span
                                            className={"badge bg-inverse-success"}
                                          >
                                            <i className="fa fa-check"></i> <span>{t('Closed')}</span>
                                          </span></> : ''
                                      }

                                      {

                                        ((new Date(task?.prazo) < new Date()) && task?.estado != 1) ? (<><span
                                          className={"badge bg-inverse-danger"}
                                        >
                                          <i className="fa fa-clock"></i> <span>{t('Pending')}</span>
                                        </span></>) : (task?.estado != 1 ? (<span
                                          className={"badge bg-inverse-warning"}
                                        >
                                          <i className="fa fa-list"></i> <span>{t('Opened')}</span>
                                        </span>) : '')
                                      }

                                    </td>
                                    <td>
                                      <span
                                        className={
                                          task?.prioridade == 1
                                            ? "badge bg-inverse-success"
                                            : task?.prioridade == 2 ? "badge bg-inverse-warning" : "badge bg-inverse-danger"
                                        }
                                      >{t('Priority')}{' : '}
                                        {task?.prioridade == 1 ? t('Low') : task?.prioridade == 2 ? t('Medium') : t('High')}
                                      </span>
                                    </td>
                                    <td>
                                      <span
                                        className={"task-label text-start"}
                                      >[{(Math.round(((task?.items?.filter(tarefa => tarefa.estado == 1).length) / task?.items?.length) * 100)) ? (Math.round(((task?.items?.filter(tarefa => tarefa.estado == 1).length) / task?.items?.length) * 100)) : 0}%]
                                      </span>
                                    </td>
                                    <td>
                                    <Link className="action-circle large delete-btn" title="Delete Task" to={"/task/tasks/" + task?.id} >
                                      <span className="task-label" >{task?.nome}
                                      </span>
                                      </Link>
                                    </td>
                                    <td>

                                      <span className="task-action-btn task-btn-right">
                                        <Link className="action-circle large delete-btn" title="Delete Task" to={"/task/tasks/" + task?.id} >
                                          <i className="fa fa-link"></i>
                                        </Link>

                                      </span>


                                    </td>
                                  </tr>

                                ))}

                            </tbody>
                          </table>

                        </div>
                      </div>
                      <div className="tab-pane" id="pending_tasks">
                        <div className="task-list-body">

                          <table className="table table-bordered" width="100%">
                            <tbody>

                              {dashboard_info?.tarefas?.filter(project => {

                                // Excluir se a missão do projeto é diferente da missão na sessão
                                if (getMissao()?.id != project?.projeto?.missao_id) {
                                  return false;
                                }

                                // Excluir se o projeto esta arquivado
                                if (project?.projeto?.estado == 2) {
                                  return false;
                                }

                                // Excluir se o tarefa esta não concluida no tempo previsto
                                if (!(project.estado == 0 && new Date(project.prazo) < new Date())) {
                                  return false;
                                }

                                // Se o tipo de usuário for 1, retorna true para todos os projetos
                                if (user.tipo == 1) {
                                  return true;
                                }

                                // Se o projeto foi criado pelo usuário
                                if (project?.projeto?.criado_por == user.id) {
                                  return true;
                                }

                                // Se o usuário é participante do projeto
                                const participante = project?.projeto?.colaboradores.find(colaborador => colaborador.colaborador_id == user.id);
                                if (participante) {
                                  return true;
                                }

                                return false;

                              }).slice(0, 7)
                                .map(task => (

                                  <tr key={task?.id}>

                                    <td>
                                      <span className="task-label text-start" >
                                        {format(new Date(task?.prazo), 'dd/MM/yyyy')}
                                      </span>
                                    </td>
                                    <td>
                                      {
                                        task?.estado == 1 ? <>
                                          <span
                                            className={"badge bg-inverse-success"}
                                          >
                                            <i className="fa fa-check"></i> <span>{t('Closed')}</span>
                                          </span></> : ''
                                      }

                                      {

                                        ((new Date(task?.prazo) < new Date()) && task?.estado != 1) ? (<><span
                                          className={"badge bg-inverse-danger"}
                                        >
                                          <i className="fa fa-clock"></i> <span>{t('Pending')}</span>
                                        </span></>) : (task?.estado != 1 ? (<span
                                          className={"badge bg-inverse-warning"}
                                        >
                                          <i className="fa fa-list"></i> <span>{t('Opened')}</span>
                                        </span>) : '')
                                      }

                                    </td>
                                    <td>
                                      <span
                                        className={
                                          task?.prioridade == 1
                                            ? "badge bg-inverse-success"
                                            : task?.prioridade == 2 ? "badge bg-inverse-warning" : "badge bg-inverse-danger"
                                        }
                                      >{t('Priority')}{' : '}
                                        {task?.prioridade == 1 ? t('Low') : task?.prioridade == 2 ? t('Medium') : t('High')}
                                      </span>
                                    </td>
                                    <td>
                                      <span
                                        className={"task-label text-start"}
                                      >[{(Math.round(((task?.items?.filter(tarefa => tarefa.estado == 1).length) / task?.items?.length) * 100)) ? (Math.round(((task?.items?.filter(tarefa => tarefa.estado == 1).length) / task?.items?.length) * 100)) : 0}%]
                                      </span>
                                    </td>
                                    <td>
                                    <Link className="action-circle large delete-btn" title="Delete Task" to={"/task/tasks/" + task?.id} >
                                      <span className="task-label" >{task?.nome}
                                      </span>
                                      </Link>
                                    </td>
                                    <td>

                                      <span className="task-action-btn task-btn-right">
                                        <Link className="action-circle large delete-btn" title="Delete Task" to={"/task/tasks/" + task?.id} >
                                          <i className="fa fa-link"></i>
                                        </Link>

                                      </span>


                                    </td>
                                  </tr>

                                ))}

                            </tbody>
                          </table>

                        </div>
                      </div>
                      <div className="tab-pane" id="completed_tasks">
                        <div className="task-list-body">
                          <table className="table table-bordered" width="100%">
                            <tbody>

                              {dashboard_info?.tarefas?.filter(project => {

                                // Excluir se a missão do projeto é diferente da missão na sessão
                                if (getMissao()?.id != project?.projeto?.missao_id) {
                                  return false;
                                }

                                // Excluir se o projeto esta arquivado
                                if (project?.projeto?.estado == 2) {
                                  return false;
                                }

                                // Excluir se o tarefa esta não concluida no tempo previsto
                                if (project.estado != 1) {
                                  return false;
                                }

                                // Se o tipo de usuário for 1, retorna true para todos os projetos
                                if (user.tipo == 1) {
                                  return true;
                                }

                                // Se o projeto foi criado pelo usuário
                                if (project?.projeto?.criado_por == user.id) {
                                  return true;
                                }

                                // Se o usuário é participante do projeto
                                const participante = project?.projeto?.colaboradores.find(colaborador => colaborador.colaborador_id == user.id);
                                if (participante) {
                                  return true;
                                }

                                return false;

                              }).slice(0, 7)
                                .map(task => (

                                  <tr key={task?.id}>

                                    <td>
                                      <span className="task-label text-start" >
                                        {format(new Date(task?.prazo), 'dd/MM/yyyy')}
                                      </span>
                                    </td>
                                    <td>
                                      {
                                        task?.estado == 1 ? <>
                                          <span
                                            className={"badge bg-inverse-success"}
                                          >
                                            <i className="fa fa-check"></i> <span>{t('Closed')}</span>
                                          </span></> : ''
                                      }

                                      {

                                        ((new Date(task?.prazo) < new Date()) && task?.estado != 1) ? (<><span
                                          className={"badge bg-inverse-danger"}
                                        >
                                          <i className="fa fa-clock"></i> <span>{t('Pending')}</span>
                                        </span></>) : (task?.estado != 1 ? (<span
                                          className={"badge bg-inverse-warning"}
                                        >
                                          <i className="fa fa-list"></i> <span>{t('Opened')}</span>
                                        </span>) : '')
                                      }

                                    </td>
                                    <td>
                                      <span
                                        className={
                                          task?.prioridade == 1
                                            ? "badge bg-inverse-success"
                                            : task?.prioridade == 2 ? "badge bg-inverse-warning" : "badge bg-inverse-danger"
                                        }
                                      >{t('Priority')}{' : '}
                                        {task?.prioridade == 1 ? t('Low') : task?.prioridade == 2 ? t('Medium') : t('High')}
                                      </span>
                                    </td>
                                    <td>
                                      <span
                                        className={"task-label text-start"}
                                      >[{(Math.round(((task?.items?.filter(tarefa => tarefa.estado == 1).length) / task?.items?.length) * 100)) ? (Math.round(((task?.items?.filter(tarefa => tarefa.estado == 1).length) / task?.items?.length) * 100)) : 0}%]
                                      </span>
                                    </td>
                                    <td>
                                    <Link className="action-circle large delete-btn" title="Delete Task" to={"/task/tasks/" + task?.id} >
                                      <span className="task-label" >{task?.nome}
                                      </span>
                                      </Link>
                                    </td>
                                    <td>

                                      <span className="task-action-btn task-btn-right">
                                        <Link className="action-circle large delete-btn" title="Delete Task" to={"/task/tasks/" + task?.id} >
                                          <i className="fa fa-link"></i>
                                        </Link>

                                      </span>


                                    </td>
                                  </tr>

                                ))}

                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>


          {/* <div className="row">
            <InvoiceTable />
            <PaymentTable />
          </div> */}

          <div className="row">
            {/* <ClientTable /> */}
            <RecentTable projetos={dashboard_info?.projetos?.slice(0,5)} />
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-12 text-center">
                  <div className="card">
                    <div className="card-body">
                      <h3 className="card-title">{t('Real Cost')}</h3>

                      {!loading ? (
                        <ResponsiveContainer width="100%" height={300}>
                          <BarChart
                            data={Object.entries(totalsByMonth).map(([month, data]) => ({
                              y: month,
                              ...data
                            }))}
                            margin={{
                              top: 5,
                              right: 5,
                              left: 5,
                              bottom: 5,
                            }}
                          >
                            <CartesianGrid />
                            <XAxis dataKey="y" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="Plano" fill="#00b0f0" />
                            <Bar dataKey="Custo Real" fill="#ed7d31" />
                          </BarChart>
                        </ResponsiveContainer>
                      ) : (
                        <div>Carregando...</div>
                      )}

                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <div className="row mt-4">
            {/* <MapChart /> */}

          </div>

        </div>
      </div>

      <TodasTarefasModalPopup tarefas={dashboard_info?.tarefas} handleNavigateTarefas={handleNavegarParaTarefa} />

      <ToastContainer />

    </div>
  );
};

export default AdminDashboard;
