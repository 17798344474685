/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-undef */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */

import React, { useState, useEffect } from "react";
import { Table } from "antd";
import Breadcrumbs from "../../../../components/Breadcrumbs";

import { useTranslation } from "react-i18next";

import AuthUser from "../../../../components/AuthUser";
import Spinner from "../../../../components/Spinner";

const PesagemDiaria = () => {
  const [pesagens, setPesagens] = useState([]);

  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const { getToken, logout } = AuthUser();

  useEffect(() => {
    if (getToken() === undefined) {
      logout();
    } else {
      // Simulating data fetch
      const fetchData = async () => {
        try {
          setLoading(true);
          await getPesagensSampleData();
          setLoading(false);
        } catch (error) {
          console.error("Erro ao obter dados:", error);
          setLoading(false);
        }
      };

      fetchData();
    }
  }, []);

  const getPesagensSampleData = async () => {
    // Sample data for "Pesagem Diária"
    const sampleData = [
      {
        id: 1,
        cavalo: "ABC-1234",
        trailer: "XYZ-5678",
        transportadora: "Transportes A",
        motorista: "João Silva",
        notaFiscal: "NF123456",
        tipoMaterial: "Grãos",
        cargaDescarga: "Carga",
        comunicado: "Supervisor A",
        pesoBruto: 20000,
        pesoTara: 5000,
        pesoLiquido: 15000,
        data: "16/11/2024",
        horarioEntrada: "08:30",
        horarioSaida: "10:00",
      },
      {
        id: 2,
        cavalo: "DEF-9012",
        trailer: "GHI-3456",
        transportadora: "Transportes B",
        motorista: "Maria Oliveira",
        notaFiscal: "NF654321",
        tipoMaterial: "Cimento",
        cargaDescarga: "Descarga",
        comunicado: "Supervisor B",
        pesoBruto: 25000,
        pesoTara: 8000,
        pesoLiquido: 17000,
        data: "16/11/2024",
        horarioEntrada: "09:15",
        horarioSaida: "11:30",
      },
      {
        id: 3,
        cavalo: "JKL-7890",
        trailer: "MNO-1122",
        transportadora: "Transportes C",
        motorista: "Carlos Santos",
        notaFiscal: "NF789456",
        tipoMaterial: "Madeira",
        cargaDescarga: "Carga",
        comunicado: "Supervisor C",
        pesoBruto: 30000,
        pesoTara: 7000,
        pesoLiquido: 23000,
        data: "15/11/2024",
        horarioEntrada: "10:00",
        horarioSaida: "12:45",
      },
      {
        id: 4,
        cavalo: "PQR-3344",
        trailer: "STU-5566",
        transportadora: "Transportes D",
        motorista: "Fernanda Lima",
        notaFiscal: "NF112233",
        tipoMaterial: "Areia",
        cargaDescarga: "Descarga",
        comunicado: "Supervisor D",
        pesoBruto: 18000,
        pesoTara: 6000,
        pesoLiquido: 12000,
        data: "15/11/2024",
        horarioEntrada: "11:30",
        horarioSaida: "13:30",
      },
      {
        id: 5,
        cavalo: "VWX-7788",
        trailer: "YZA-9900",
        transportadora: "Transportes E",
        motorista: "Roberto Costa",
        notaFiscal: "NF445566",
        tipoMaterial: "Ferro",
        cargaDescarga: "Carga",
        comunicado: "Supervisor E",
        pesoBruto: 40000,
        pesoTara: 10000,
        pesoLiquido: 30000,
        data: "14/11/2024",
        horarioEntrada: "07:45",
        horarioSaida: "09:30",
      },
    ];

    setPesagens(sampleData);
  };

  const columns = [
    {
      title: t("ID"),
      dataIndex: "id",
      render: (text) => <span>{text}</span>,
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: t("Placa Cavalo"),
      dataIndex: "cavalo",
      render: (text) => <span>{text}</span>,
    },
    {
      title: t("Placa Trailer"),
      dataIndex: "trailer",
      render: (text) => <span>{text}</span>,
    },
    {
      title: t("Transportadora"),
      dataIndex: "transportadora",
      render: (text) => <span>{text}</span>,
      sorter: (a, b) => a.transportadora.localeCompare(b.transportadora),
    },
    {
      title: t("Motorista"),
      dataIndex: "motorista",
      render: (text) => <span>{text}</span>,
      sorter: (a, b) => a.motorista.localeCompare(b.motorista),
    },
    {
      title: t("Nota Fiscal"),
      dataIndex: "notaFiscal",
      render: (text) => <span>{text}</span>,
    },
    {
      title: t("Tipo de Material"),
      dataIndex: "tipoMaterial",
      render: (text) => <span>{text}</span>,
    },
    {
      title: t("Carga/Descarga"),
      dataIndex: "cargaDescarga",
      render: (text) => <span>{text}</span>,
    },
    {
      title: t("Comunicado a"),
      dataIndex: "comunicado",
      render: (text) => <span>{text}</span>,
    },
    {
      title: t("Peso Bruto"),
      dataIndex: "pesoBruto",
      render: (text) => <span>{text} kg</span>,
    },
    {
      title: t("Peso Tara"),
      dataIndex: "pesoTara",
      render: (text) => <span>{text} kg</span>,
    },
    {
      title: t("Peso Líquido"),
      dataIndex: "pesoLiquido",
      render: (text) => <span>{text} kg</span>,
    },
    {
      title: t("Data"),
      dataIndex: "data",
      render: (text) => <span>{text}</span>,
    },
    {
      title: t("Horário de Entrada"),
      dataIndex: "horarioEntrada",
      render: (text) => <span>{text}</span>,
    },
    {
      title: t("Horário de Saída"),
      dataIndex: "horarioSaida",
      render: (text) => <span>{text}</span>,
    },
  ];

  const totalPesagens = pesagens.length;
  const totalCargas = pesagens.filter(
    (p) => p.cargaDescarga === "Carga"
  ).length;
  const totalDescargas = pesagens.filter(
    (p) => p.cargaDescarga === "Descarga"
  ).length;
  const pesoTotalLiquido = pesagens.reduce(
    (total, p) => total + p.pesoLiquido,
    0
  );

  if (loading)
    return (
      <>
        <Spinner></Spinner>
      </>
    );
  else
    return (
      <>
        <div className="page-wrapper">
          {/* Page Content */}
          <div className="content container-fluid">
            {/* Page Header */}
            <Breadcrumbs maintitle={t("Pesagem Diária")} title="Operações" />

            {/* Estatísticas */}
            <div className="row">
              <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                <div className="card dash-widget">
                  <div className="card-body">
                    <span className={`dash-widget-icon fa fa-cubes`} />
                    <div className="dash-widget-info">
                      <h3>{totalPesagens}</h3>
                      <span>Total de Pesagens</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                <div className="card dash-widget">
                  <div className="card-body">
                    <span
                      className={`dash-widget-icon fa fa-arrow-circle-up`}
                    />
                    <div className="dash-widget-info">
                      <h3>{totalCargas}</h3>
                      <span>Total de Cargas</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                <div className="card dash-widget">
                  <div className="card-body">
                    <span className={`dash-widget-icon fa fa-arrow-circle-down`} />
                    <div className="dash-widget-info">
                      <h3>{totalDescargas}</h3>
                      <span>Total Descargas</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                <div className="card dash-widget">
                  <div className="card-body">
                    <span className={`dash-widget-icon fa fa-building`} />
                    <div className="dash-widget-info">
                      <h3>{pesoTotalLiquido}</h3>
                      <span>Peso Total Liquido</span>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="table-responsive">
                  <Table
                    columns={columns}
                    dataSource={pesagens}
                    rowKey="id"
                    pagination={{ pageSize: 5 }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
};

export default PesagemDiaria;
