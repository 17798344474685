import { useTranslation } from "react-i18next";

import React, { useState, useEffect } from "react";

import { toast, ToastContainer } from 'react-toastify';

import AuthUser from "../AuthUser";

const DiretorioFicheiroModal = ({ onFileNameSubmissionSuccess, onReceberNome, name, tipo, para_id, accao, id, nomeEdit }) => {

  // eslint-disable-next-line no-unused-vars
  const { http, url_root, user_permissoes, verificarPermissao, getToken, user, getMissao, logout } = AuthUser();

  const { t } = useTranslation();

  const [nome, setName] = useState('');
  const [loading, setLoading] = useState(false);

  const triggerCloseButtonClick = (buttonId) => {
    const closeButton = document.getElementById(buttonId);
    if (closeButton) {
      closeButton.dispatchEvent(new Event('click'));
    }
  };

  const criar_diretorio = async () => {
    try {

      const formData = new FormData();
      formData.append('missao_id', getMissao()?.id);
      formData.append('descricao', nome);
      formData.append('para_id', para_id);
      formData.append('tipo', tipo);

      console.log('tipo', tipo);
      console.log('descricao', nome);
      console.log('para_id', para_id);

      const response = await http.post('/criar-arquivo', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      triggerCloseButtonClick('closeButton10');
      onFileNameSubmissionSuccess(para_id);

    } catch (error) {
      console.error(error);

      triggerCloseButtonClick('closeButton10');

      toast.error('Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const renomearArquivo = async () => {
    try {

      setLoading(true);

      console.log('nome', nome);
      console.log('id', id)

      const formData = new FormData();
      formData.append('descricao', nome);
      formData.append('id', id);

      const response = await http.post('/renomear-arquivo', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });


      triggerCloseButtonClick('closeButton10');
      onFileNameSubmissionSuccess(para_id);

      setLoading(false);

    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };


  useEffect(() => {

    const fetchData = async () => {
      try {

        if (accao == 1)
          setName(name);
        else
          setName(nomeEdit);

      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
      }
    };

    fetchData();

  }, [name, nomeEdit]);


  //registar quadro

  const submitNome = async () => {

    // Basic form validation
    if (nome.length < 2) {

      // Show a error toast
      toast.error('O nome deve ter pelo menos dois caracteres.', {
        position: 'top-right',
        autoClose: 3000, // Close the toast after 3 seconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

      return;
    } 


    triggerCloseButtonClick('closeButton10');

    onFileNameSubmissionSuccess(nome);

  };




  return (
    <>
      {/* Add Lista Modal */}
      <div
        id="add_file"
        className="modal custom-modal fade"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">{t('Name')}</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                id="closeButton10"
              >
                ×
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="input-block mb-3">
                  <label className="col-form-label">{t('Type a name')}</label>
                  <input type="text" className="form-control" value={nome} onChange={e => setName(e.target.value)} />
                </div>

                <div className="submit-section m-t-20 text-center">
                  <a
                    className="btn btn-primary submit-btn"
                    onClick={accao == 1 ? criar_diretorio : renomearArquivo}>
                    {t('Save')}
                  </a>

                </div>
              </form>
            </div>
          </div>
        </div>

      </div>




      <ToastContainer />

    </>
  );
};

export default DiretorioFicheiroModal;
