import React, { useState, useEffect } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
  BarChart,
  Bar,
} from "recharts";
import { useTranslation } from "react-i18next";

const ChartsRH = ({ absentismoMes, salariosPorCargo }) => {
  const { t } = useTranslation();

  const [absentismoData, setAbsentismoData] = useState([]);
  const [salariosData, setSalariosData] = useState([]);

  useEffect(() => {
    setAbsentismoData(absentismoMes);
    setSalariosData(salariosPorCargo);
  }, [absentismoMes, salariosPorCargo]);

  return (
    <div>


      {/* <div className="row">
        <div className="col-md-12 text-center">
          <div className="card">
            <div className="card-body">
              <h3 className="card-title">{t("Absentismo no Mês")}</h3>
              <ResponsiveContainer width="100%" height={300}>
                <LineChart
                  data={absentismoData}
                  margin={{ top: 5, right: 5, left: 5, bottom: 5 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="dia" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line
                    type="monotone"
                    dataKey="total"
                    stroke="#8884d8"
                    activeDot={{ r: 8 }}
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>       
      </div> */}

      <div className="row">
       
        {/* Gráfico de Salários por Cargo */}
        <div className="col-md-12 text-center">
          <div className="card">
            <div className="card-body">
              <h3 className="card-title">{t("Salários por Cargos")}</h3>
              <ResponsiveContainer width="100%" height={300}>
                <BarChart
                  data={salariosData}
                  margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="cargo" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="total" fill="#8884d8" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChartsRH;
